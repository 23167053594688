import React, { useEffect, useMemo, useState } from 'react';
import Image from 'gatsby-image';
import styled, { css } from 'styled-components';

import { breakpoint } from '../../../constants/Breakpoint';
import { getImage, getMetafield } from '~/helpers';
import { HeadingLarge, Subheading } from '~/components/.base/headings';
import { BackgroundDiv } from '~/components/.base/containers';
import Slideshow from '~/components/.base/slideshow';
import { WoodenBorder } from '../../.base/border';

const ProductOriginsWrapper = styled.div`
    margin-bottom: 150px;
    position: relative;
    .carousel-root {
        .product-origins-slide {
            max-height: 70vh;
            min-height: 500px;
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .slide-details {
                position: absolute;
                text-align: center;
                width: 100%;
                max-width: 415px;
                margin: 0 auto;
                color: #fff;
            }
        }
        .carousel {
            &.carousel-slider {
                overflow: visible;
            }
            .control-dots {
                bottom: -30px;
                left: calc(50% - 20px);
                width: fit-content;
                z-index: 2;
                .circle {
                    .inner {
                        background: rgba(92, 101, 102, 0.7);
                    }
                    &.selected .inner {
                        background-color: #d4212c;
                    }
                }
            }
            .prev-next {
                bottom: -30px;
                z-index: 2;
                &.next {
                    left: auto;
                    right: calc(50% + 60px);
                }
                &.prev {
                    left: auto;
                    right: calc(50% + 100px);
                }
            }
        }
    }
    @media ${breakpoint.maxMd} {
        margin-bottom: 80px;
        .carousel-root {
            .product-origins-slide {
                min-height: 0px;
                .slide-details {
                    left: 5%;
                    width: 90%;
                }
            }
            .carousel {
                .control-dots {
                    bottom: -40px;
                }
                .prev-next {
                    bottom: -40px;
                }
            }
        }
    }
`;

const ProductOrigins = ({ productImages, originsRef, metafields }) => {
    const data = useMemo(() => {
        const originHeaders = getMetafield(metafields, 'product_origins_header')
            ? JSON.parse(getMetafield(metafields, 'product_origins_header'))
            : [];
        const originSubHeaders = getMetafield(metafields, 'product_origins_subheading')
            ? JSON.parse(getMetafield(metafields, 'product_origins_subheading'))
            : [];
        const originImages = getMetafield(metafields, 'product_origins_image')
            ? JSON.parse(getMetafield(metafields, 'product_origins_image'))
            : [];
        return {
            originHeaders,
            originSubHeaders,
            originImages,
        };
    }, [metafields]);

    return (
        data.originHeaders.length > 0 && (
            <ProductOriginsWrapper id="product-origins-main" ref={originsRef} tabIndex="-1">
                <WoodenBorder top="0" zIndex="1" />
                <WoodenBorder top="100%" zIndex="1" />
                <BackgroundDiv
                    css={css`
                        width: 19vw;
                        max-width: 256px;
                        right: 0;
                        top: 0;
                        transform: translateY(-100%);
                    `}
                >
                    <Image fluid={getImage(productImages, 'hand')} alt="hand" />
                </BackgroundDiv>
                {/* Commented bc the image was affecting the visibility of sticky nav */}
                {/* <BackgroundDiv css={css`
				width: 228px;
				max-width: 100%;
				left: 0;
				top: 0;
				transform: translateY(-50%);
				z-index: 1;
			`}>
				<Image fluid={getImage(productImages, 'product-origins-blur')} alt="blur" />
			</BackgroundDiv> */}
                <div
                    css={css`
                        position: relative;
                    `}
                ></div>
                <Slideshow carouselLabel="product origins">
                    {data.originHeaders.map((originHeader, i) => (
                        <div className="product-origins-slide" key={i}>
                            <picture style={{ width: '100%' }}>
                                <source
                                    media="(min-width:2048px)"
                                    srcset={`${data.originImages[i][0].cloudinary_src}w_2048`}
                                />
                                <source
                                    media="(min-width:1024px)"
                                    srcset={`${data.originImages[i][0].cloudinary_src}w_1024`}
                                />
                                <source
                                    media="(min-width:600px)"
                                    srcset={`${data.originImages[i][0].cloudinary_src}w_600`}
                                />
                                <source
                                    media="(min-width:480px)"
                                    srcset={`${data.originImages[i][0].cloudinary_src}w_480`}
                                />
                                <source
                                    media="(min-width:240px)"
                                    srcset={`${data.originImages[i][0].cloudinary_src}w_240`}
                                />
                                <img
                                    src={`${data.originImages[i][0].cloudinary_src}w_800`}
                                    alt="Product Origins"
                                    style={{ zIndex: '-2' }}
                                />
                            </picture>
                            <div className="slide-details">
                                <Subheading color="#fff">{data.originSubHeaders[i]}</Subheading>
                                <HeadingLarge as="h2" className="slide-title" color="#fff">
                                    {originHeader}
                                </HeadingLarge>
                            </div>
                        </div>
                    ))}
                </Slideshow>
            </ProductOriginsWrapper>
        )
    );
};

export default ProductOrigins;
