import { getShopifyImageTransformedSrc } from "..";

export const getProductJSONLD = (product) => {
  let microData = {};
  if (product && typeof window !== "undefined") {
    const days_product_price_valid_until =
      10 * 86400 * 1000; /* 10 days into ms */
    const offers = product.variants.map(
      ({ title, availableForSale, price, sku, id }) => {
        const variantData = {
          "@type": "Offer",
          name: title === "Default Title" ? product.title : title,
          availability: `${
            availableForSale
              ? "https://schema.org/InStock"
              : "https://schema.org/OutOfStock"
          }`,
          price: price,
          priceCurrency: product.priceRange.maxVariantPrice.currencyCode,
          priceValidUntil: new Date(
            new Date().getTime() + days_product_price_valid_until
          )
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace("/", "-")
            .replace("/", "-"),
          url: `/products/${product.handle}?variant=${
            id.replace("Shopify__ProductVariant__", ""
          ).replace("gid://shopify/ProductVariant/", "")}`,
        };
        if (sku) {
          variantData.sku = sku;
        }
        return variantData;
      }
    );
    microData = {
      "@context": "http://schema.org",
      "@type": "Product",
      offers: offers,
      brand: {
        name: product.vendor,
      },
      name: product.title,
      description: product.description,
      category: product.productType,
      url: `/products/${product.handle}`,
      sku: product.variants[0].sku,
    };

    if (product.images && product.images.length > 0) {
      microData.image = {
        "@type": "ImageObject",
        url: getShopifyImageTransformedSrc(
          product.images[0].originalSrc,
          "_1024x"
        ),
        image: getShopifyImageTransformedSrc(
          product.images[0].originalSrc,
          "_1024x"
        ),
        name: product.title,
        width: "1024",
        height: "1024",
      };
    }

    return JSON.stringify(microData);
  }

  return null;
};
