import { useCallback } from 'react';

export const useChangeVariant = ({
    locationSearch,
    product,
    setSelectedVariant,
    setSelectedSubscriptionVariant,
    setSelectedRegularVariant,
    subscriptionProduct,
}) => {
    const handleOnVariantChange = useCallback(() => {
        const params = new URLSearchParams(locationSearch);
        const variantId = params.get('variant');

        if (variantId) {
            const paramVariant = product.variants.find(
                v => v.shopifyId === `gid://shopify/ProductVariant/${variantId}`
            );
            if (paramVariant) {
                const varIndex = product.variants.indexOf(paramVariant);
                if (varIndex > -1) {
                    if (subscriptionProduct) {
                        setSelectedVariant(product.variants[varIndex]);
                        setSelectedSubscriptionVariant(subscriptionProduct.variants[varIndex]);
                        setSelectedRegularVariant(product.variants[varIndex]);
                    } else {
                        setSelectedVariant(product.variants[varIndex]);
                        setSelectedRegularVariant(product.variants[varIndex]);
                    }
                }
            }
        }
    }, [
        locationSearch,
        product,
        setSelectedVariant,
        setSelectedSubscriptionVariant,
        setSelectedRegularVariant,
    ]);
    return {
        handleOnVariantChange,
    };
};
