import React from 'react';
import Modal from '~/components/.base/modal';
import { Heading, BodyText } from '~/components/.base/headings';
import { RedButton, WhiteButton } from '~/components/.base/buttons';
import { FlexBox } from '~/components/.base/containers';
import './ShippingModal.scss';

const shippingModalContent = [
    {
        title: 'Fast and Secure Shipping',
        description:
            'We ship our fresh, frozen and live seafood products next-day air.  Order by 8am EST and your order can ship same day, on eligible shipping days.  Sunday and Monday deliveries not available.  Saturday delivery unavailable in some areas.  ',
    },
    {
        title: 'Handpacked with Care',
        description:
            'Your order will be packed with care by a CML team member, ensuring your product arrives on time and in perfect condition.',
    },
];

const ShippingModal = ({
    shippingModalIsOpen,
    setShippingModalIsOpen,
    modalIndex,
    setModalIndex,
}) => {
    return (
        <Modal
            open={shippingModalIsOpen}
            onClose={() => setShippingModalIsOpen(false)}
            ariaLabelledby="shipping-modal-title"
            modalMaxWidth="650px"
            top="0"
        >
            <div className="shipping-modal">
                <div className="shipping-modal-header">
                    <Heading id="shipping-modal-title">
                        {shippingModalContent[modalIndex]?.title}
                    </Heading>
                </div>
                <div className="shipping-modal-body">
                    <FlexBox>
                        <BodyText>{shippingModalContent[modalIndex]?.description}</BodyText>
                    </FlexBox>
                </div>
                <div className="shipping-modal-footer">
                    <FlexBox justify="flex-end">
                        <WhiteButton
                            onClick={() =>
                                setModalIndex(idx => {
                                    if (idx === 0) {
                                        return 1;
                                    } else {
                                        return idx - 1;
                                    }
                                })
                            }
                        >
                            Previous
                        </WhiteButton>
                        <RedButton
                            onClick={() =>
                                setModalIndex(idx => {
                                    if (idx === 1) {
                                        return 0;
                                    } else {
                                        return idx + 1;
                                    }
                                })
                            }
                        >
                            Next
                        </RedButton>
                    </FlexBox>
                </div>
            </div>
        </Modal>
    );
};

export default ShippingModal;
