import React, { useState, useEffect, useMemo } from 'react';
import Image from 'gatsby-image';
import { css } from 'styled-components';
// import { gsap } from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
import { Heading, BodyTextAlt } from '~/components/.base/headings';
import { Row, Col, FlexBox } from '~/components/.base/containers';
import { RedButton, WhiteButton, WhiteSubscriptionCircleButton } from '~/components/.base/buttons';
import { InputSelect } from '~/components/.base/inputs';
import ArrowDown from '~/images/icons/arrow_normal_down.svg';
import PlusIcon from '~/images/icons/plus.svg';
import MinusIcon from '~/images/icons/minus.svg';
import CheckIcon from '~/images/icons/pdp/checkbox_2.svg';
import SubscriptionIcon from '~/images/icons/pdp/subscription.svg';
import './StickyATC.scss';
import no_image from '~/images/collectionList/noimage.jpeg';

const StickyATC = ({
    product,
    quantity,
    setQuantity,
    addToCart,
    isSubscription,
    subscribing,
    setSubscribing,
    setSubscriptionModalIsOpen,
    selectedVariant,
    setSelectedVariant,
    selectedRegularVariant,
    setSelectedRegularVariant,
    activeQuantityDiscount,
    quantityDiscountInEffect,
    availableForSale,
    loadingAvailability,
    varAvailableQuantity,
}) => {
    const { variants } = product;
    const handleVariantChange = e => {
        const selVar = product.variants.find(p => p.shopifyId === e.target.value);
        setSelectedVariant(selVar);
        setSelectedRegularVariant(selVar);
    };
    const [windowLoaded, setWindowLoaded] = useState(false);
    const [domLoaded, setDomLoaded] = useState(false);
    const [windowWidth, setWindowWidth] = useState(0);
    const [refetch, setRefetch] = useState(false);
    // gsap.registerPlugin(ScrollTrigger);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (window) {
            // timeoutId for debounce mechanism
            const getWidth = () =>
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;
            let timeoutId = null;
            setWindowWidth(getWidth());
            const resizeListener = () => {
                // prevent execution of previous setTimeout
                clearTimeout(timeoutId);
                // change width from the state object after 150 milliseconds
                timeoutId = setTimeout(() => setWindowWidth(getWidth()), 150);
            };
            // set resize listener
            window.addEventListener('resize', resizeListener);

            document.addEventListener('DOMContentLoaded', function (event) {
                setDomLoaded(true);
            });
            window.addEventListener('load', event => {
                // Page is fully loaded
                setWindowLoaded(true);
            });
            return () => {
                setWindowLoaded(false);
                setDomLoaded(false);
                setWindowWidth(false);
                window.removeEventListener('resize', resizeListener);
                window.removeEventListener('DOMContentLoaded', resizeListener);
                window.removeEventListener('load', resizeListener);
            };
        }
    }, []);
    useEffect(() => {
        if (window) {
            var lastScrollTop = 0;
            var stickyATC = document.querySelector('#sticky-atc');
            var stickyATCMobile = document.querySelector('#sticky-atc-mobile');
            var stickyNav = document.querySelector('.ecomm-navigation');
            var mobileNav = stickyNav;
            var mobileFooter = document.querySelector('#footer-mobile');
            var cartAnchor = document.querySelector('#cart-anchor');

            const headerScroll = function () {
                var st = window.pageYOffset;
                if (window.innerWidth > 950) {
                    if (stickyATC && stickyNav) {
                        if (cartAnchor.getBoundingClientRect().top > 0) {
                            setIsVisible(false);
                            stickyATC.classList.remove('visible');
                            stickyNav.classList.remove('has-atc');
                        } else {
                            setIsVisible(true);
                            stickyATC.classList.add('visible');
                            stickyNav.classList.add('has-atc');
                        }
                        if (st < 200) {
                            // stickyNav.classList.add("hidden");
                        } else {
                            if (st > lastScrollTop) {
                                stickyATC.classList.remove('scroll-up');
                                // stickyNav.classList.add("hidden");
                            } else {
                                stickyATC.classList.add('scroll-up');
                                // stickyNav.classList.remove("hidden");
                            }
                        }
                        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
                    } else {
                        setTimeout(() => setRefetch(r => !r), 1000);
                    }
                } else {
                    if (stickyATCMobile && mobileNav) {
                        if (st < 1000) {
                            stickyATCMobile.classList.remove('visible-mobile');
                            mobileNav.classList.remove('has-atc');
                            mobileFooter.classList.remove('has-sticky-atc');
                        } else {
                            stickyATCMobile.classList.add('visible-mobile');
                            mobileNav.classList.add('has-atc');
                            mobileFooter.classList.add('has-sticky-atc');
                        }
                        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
                    } else {
                        setTimeout(() => setRefetch(r => !r), 1000);
                    }
                }
            };
            const reqFrame = () => {
                requestAnimationFrame(headerScroll);
            };
            window.addEventListener('scroll', reqFrame, false);
            return function cleanup() {
                window.removeEventListener('scroll', reqFrame);
            };

            // let docHeight = document.querySelector("body").offsetHeight
            // let scrollTriggers = []
            // let st = ScrollTrigger.create({
            //   trigger: "document",
            //   start: "top top",
            //   end: () => `+=${document.querySelector("body").offsetHeight}`,
            //   scrub: 0,
            //   onUpdate: ({progress, direction}) => {
            // 	let scrollTop = window.scrollY;
            // 	if (windowWidth > 950) {
            // 		if (stickyATC && stickyNav) {
            // 			console.log(scrollTop, direction)
            // 			if (cartAnchor.getBoundingClientRect().top > 0) {
            // 				stickyATC.classList.remove("visible");
            // 				// stickyNav.classList.remove("has-atc");
            // 			} else {
            // 				stickyATC.classList.add("visible");
            // 				stickyNav.classList.add("has-atc");
            // 				// stickyNav.classList.add("has-atc");
            // 			}
            // 			if (scrollTop < 200) {
            // 				// stickyNav.classList.add("hidden");
            // 			} else {
            // 				if (direction === 1) {
            // 					stickyATC.classList.remove("scroll-up");
            // 					// stickyNav.classList.add("hidden");
            // 				} else {
            // 					stickyATC.classList.add("scroll-up");
            // 					// stickyNav.classList.remove("hidden");
            // 				}
            // 			}
            // 		} else {
            // 			setTimeout(() => setRefetch((r) => !r), 1000);
            // 		}
            // 	} else {
            // 		if (stickyATCMobile && mobileNav) {
            // 			if (scrollTop < 1000) {
            // 				stickyATCMobile.classList.remove("visible-mobile");
            // 				mobileNav.classList.remove("has-atc");
            // 				mobileFooter.classList.remove("has-sticky-atc");
            // 			} else {
            // 				stickyATCMobile.classList.add("visible-mobile");
            // 				mobileNav.classList.add("has-atc");
            // 				mobileFooter.classList.add("has-sticky-atc");
            // 			}
            // 		} else {
            // 			setTimeout(() => setRefetch((r) => !r), 1000);
            // 		}
            // 	}
            //   },
            //   onRefresh:() => {
            // 	docHeight = document.querySelector("body").offsetHeight
            // 	console.log(docHeight)
            //   }
            // })
            // scrollTriggers.push(st)
            // // Specify how to clean up after this effect:
            // return () => {
            // 	// all garbage cleanup all goes in a single useEffect for when component leaves
            // 	if(scrollTriggers) {
            // 		scrollTriggers.map((st) => {
            // 		st.kill()
            // 		return true
            // 		})
            // 	}
            // }
        }
    }, [windowLoaded, domLoaded, windowWidth, refetch]);

    return (
        <>
            <div id="sticky-atc" className="sticky-atc">
                {isVisible && (
                    <Row justify={'space-between'}>
                        <Col size={1}>
                            <FlexBox align="center" justify="flex-start">
                                <div className="img-cont">
                                    {product?.images?.[0]?.localFile?.childImageSharp?.fluid ? (
                                        <Image
                                            fluid={
                                                product.images[0].localFile.childImageSharp.fluid
                                            }
                                            alt=""
                                        />
                                    ) : (
                                        <img src={no_image} alt="" />
                                    )}
                                </div>
                                <Heading md="0 0 0 10px">
                                    <span className="red">
                                        <span className="dollar-sign">$</span>
                                        <span className="price">
                                            {quantityDiscountInEffect
                                                ? subscribing
                                                    ? `${(
                                                          ((activeQuantityDiscount.price_per_unit *
                                                              9) /
                                                              10) *
                                                          quantity
                                                      ).toFixed(2)}`
                                                    : `${(
                                                          activeQuantityDiscount.price_per_unit *
                                                          quantity
                                                      ).toFixed(2)}`
                                                : `${(
                                                      Number(selectedVariant.price.amount) * quantity
                                                  ).toFixed(2)}`}
                                        </span>
                                    </span>
                                </Heading>
                            </FlexBox>
                        </Col>
                        <Col size={1}>
                            <FlexBox align="center" justify="center">
                                <Heading md="0">{product.title}</Heading>
                            </FlexBox>
                        </Col>
                        <Col size={1}>
                            <FlexBox align="center" justify="flex-end">
                                {/* NOTE: commented next section until subscription is re-implemented */}
                                {/* {isSubscription ? (
                                    subscribing ? (
                                        <div className="sticky-sub-button">
                                            <WhiteSubscriptionCircleButton
                                                h="40px"
                                                w="40px"
                                                pd="0px"
                                                md="0 5px 0 0"
                                                className="subscription-btn no-stroke"
                                                onClick={() => setSubscribing(false)}
                                            >
                                                <SubscriptionIcon />
                                            </WhiteSubscriptionCircleButton>
                                            <CheckIcon className="check" />
                                        </div>
                                    ) : (
                                        <WhiteSubscriptionCircleButton
                                            h="40px"
                                            w="40px"
                                            pd="0px"
                                            md="0 5px 0 0"
                                            className="subscription-btn no-stroke"
                                            onClick={() => setSubscriptionModalIsOpen(true)}
                                        >
                                            <SubscriptionIcon />
                                        </WhiteSubscriptionCircleButton>
                                    )
                                ) : null} */}
                                {product.options[0].name !== 'Title' ? (
                                    <InputSelect
                                        className="sticky-form-select"
                                        mb="0"
                                        style={{
                                            height: '40px',
                                            marginRight: '5px',
                                        }}
                                    >
                                        <select
                                            value={selectedVariant.shopifyId}
                                            onChange={handleVariantChange}
                                            onBlur={handleVariantChange}
                                        >
                                            {variants.map((variant, i) => (
                                                <option key={i} value={variant.shopifyId}>
                                                    {variant.title}
                                                </option>
                                            ))}
                                        </select>
                                        <ArrowDown style={{ pointerEvents: 'none' }} />
                                    </InputSelect>
                                ) : null}
                                <div className="quantity">
                                    <button
                                        className="quantity__adjust"
                                        disabled={
                                            quantity === 1 ||
                                            (product.handle === 'live-lobsters-minimum-2' &&
                                                quantity === 2)
                                        }
                                        onClick={() => {
                                            setQuantity(q => (q > 1 ? q - 1 : 1));
                                        }}
                                    >
                                        <MinusIcon />
                                    </button>
                                    {quantity < 10 ? `0${quantity}` : quantity}
                                    <button
                                        className="quantity__adjust"
                                        disabled={varAvailableQuantity === quantity}
                                        onClick={() => setQuantity(q => q + 1)}
                                    >
                                        <PlusIcon style={{ marginBottom: '1px' }} />
                                    </button>
                                </div>
                                <RedButton
                                    onClick={addToCart}
                                    disabled={!selectedVariant.availableForSale}
                                >
                                    {selectedVariant.availableForSale
                                        ? `Add ${subscribing ? 'subscription ' : ''}to cart`
                                        : 'Out of stock'}
                                </RedButton>
                            </FlexBox>
                        </Col>
                    </Row>
                )}
            </div>
            <div id="sticky-atc-mobile" className="sticky-atc sticky-atc-mobile">
                {isVisible && (
                    <>
                        <Row className="upper">
                            <Col size={5}>
                                <FlexBox align="center" justify="flex-start">
                                    <div className="img-cont">
                                        {product?.images?.[0]?.localFile?.childImageSharp?.fluid ? (
                                            <Image
                                                fluid={
                                                    product.images[0].localFile.childImageSharp
                                                        .fluid
                                                }
                                                alt={product.title}
                                            />
                                        ) : (
                                            <img src={no_image} alt="" />
                                        )}
                                    </div>
                                    <BodyTextAlt md="0 0 0 .75rem">{product.title}</BodyTextAlt>
                                </FlexBox>
                            </Col>
                            <Col size={2}>
                                <FlexBox align="center" justify="flex-end">
                                    <BodyTextAlt color="#d4212c" md="0">
                                        <span className="red">
                                            {quantityDiscountInEffect
                                                ? subscribing
                                                    ? `$${(
                                                          (activeQuantityDiscount.price_per_unit *
                                                              9) /
                                                          10
                                                      ).toFixed(2)}`
                                                    : `$${activeQuantityDiscount.price_per_unit.toFixed(
                                                          2
                                                      )}`
                                                : `$${selectedVariant.price.amount}`}
                                        </span>
                                    </BodyTextAlt>{' '}
                                </FlexBox>
                            </Col>
                        </Row>
                        <div className="lower">
                            <FlexBox align="center" justify="center">
                                {/* NOTE: commented next section until subscription is re-implemented */}
                                {/* {isSubscription ? (
                                    subscribing ? (
                                        <div className="sticky-sub-button">
                                            <WhiteButton
                                                h="40px"
                                                w="40px"
                                                pd="0px"
                                                md="0 5px 0 0"
                                                className="subscription-btn"
                                                onClick={() => setSubscribing(false)}
                                            >
                                                <SubscriptionIcon />
                                            </WhiteButton>
                                            <CheckIcon className="check" />
                                        </div>
                                    ) : (
                                        <WhiteButton
                                            h="40px"
                                            w="40px"
                                            pd="0px"
                                            md="0 5px 0 0"
                                            className="subscription-btn"
                                            onClick={() => setSubscriptionModalIsOpen(true)}
                                        >
                                            <SubscriptionIcon />
                                        </WhiteButton>
                                    )
                                ) : null} */}
                                {product.options[0].name !== 'Title' ? (
                                    <InputSelect
                                        className="sticky-form-select"
                                        mb="0px"
                                        style={{ height: '40px', marginRight: '5px' }}
                                    >
                                        <select
                                            value={selectedRegularVariant.shopifyId}
                                            onChange={handleVariantChange}
                                            onBlur={handleVariantChange}
                                        >
                                            {variants.map((variant, i) => (
                                                <option key={i} value={variant.shopifyId}>
                                                    {variant.title}
                                                </option>
                                            ))}
                                        </select>
                                        <ArrowDown style={{ pointerEvents: 'none' }} />
                                    </InputSelect>
                                ) : null}
                                <div className="quantity">
                                    <button
                                        className="quantity__adjust"
                                        disabled={
                                            quantity === 1 ||
                                            (product.handle === 'live-lobsters-minimum-2' &&
                                                quantity === 2)
                                        }
                                        onClick={() => setQuantity(q => q - 1)}
                                    >
                                        <MinusIcon />
                                    </button>
                                    {quantity < 10 ? `0${quantity}` : quantity}
                                    <button
                                        className="quantity__adjust"
                                        disabled={varAvailableQuantity === quantity}
                                        onClick={() => setQuantity(q => q + 1)}
                                    >
                                        <PlusIcon style={{ marginBottom: '1px' }} />
                                    </button>
                                </div>
                            </FlexBox>
                            <RedButton
                                onClick={addToCart}
                                disabled={!availableForSale || loadingAvailability}
                            >
                                {availableForSale || loadingAvailability
                                    ? `Add ${subscribing ? 'subscription ' : ''}to cart`
                                    : 'Out of stock'}
                            </RedButton>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default StickyATC;
