import React, { useState } from 'react';
import Image from 'gatsby-image';
import styled from 'styled-components';

import { getImage } from '~/helpers';
import { BodyText } from '~/components/.base/headings';
import { WhiteButton } from '~/components/.base/buttons';
import { FlexBox, Row, Col } from '~/components/.base/containers';
import LobsterIcon from '~/images/icons/lobster.svg';
import CalendarIcon from '~/images/icons/pdp/calendar.svg';
import BoxIcon from '~/images/icons/pdp/BIG_PACKAGE.svg';
import { ShippingModal } from '~/components/ProductPage';
import { breakpoint } from '../../../constants/Breakpoint';

const ShippingInfoWrapper = styled.div`
    position: relative;
    padding: 0 0 200px;
    .shipping-lines {
        width: 90%;
        max-width: 734px;
        margin: auto;
        .shipping-line {
            margin-bottom: 1rem;
            padding: 0.75rem 0;
            border-bottom: 1px solid #e2e2e4;
            svg {
                width: 32px;
                max-height: 40px;
            }
        }
    }
    .hand {
        position: absolute !important;
        right: 0;
        bottom: 0;
    }
    .blur {
        display: none;
        width: 10vw;
        max-width: 135px;
        position: absolute !important;
        left: 0;
        bottom: -11vw;
        z-index: 2;
    }
    @media ${breakpoint.maxSm} {
        padding: 0 0 100px;
        .shipping-lines {
            width: 90%;
            .shipping-line {
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 1rem;
                padding: 0.75rem 0;
                border-bottom: none;
                button {
                    margin: 20px 0 10px;
                    width: 100%;
                    height: 60px;
                }
            }
        }
        .hand {
            width: 145px;
        }
        .blur {
            display: block;
        }
    }
`;

const ShippingInfo = ({ productImages, showStickyMenu, shippingRef }) => {
    const [modalIndex, setModalIndex] = useState(0);
    const [shippingModalIsOpen, setShippingModalIsOpen] = useState(false);
    return (
        <ShippingInfoWrapper>
            <div id="shipping-info" style={{ position: 'relative', top: '-20vh' }} tabIndex="-1" />
            <Row ref={shippingRef}>
                {showStickyMenu ? <Col className="sticky-menu-col" size={1}></Col> : null}
                <Col size={4}>
                    <div className="shipping-lines">
                        <FlexBox justify="space-between" align="center" className="shipping-line">
                            <FlexBox align="center">
                                {/* <CalendarIcon2 /> */}
                                <CalendarIcon style={{ width: 24, height: 'auto' }} />
                                <BodyText md="0 0 0 .75rem">Fast and Secure Shipping</BodyText>
                            </FlexBox>
                            <WhiteButton
                                onClick={() => {
                                    setModalIndex(0);
                                    setShippingModalIsOpen(true);
                                }}
                            >
                                View Details
                            </WhiteButton>
                        </FlexBox>
                        <FlexBox justify="space-between" align="center" className="shipping-line">
                            <FlexBox align="center">
                                <BoxIcon />
                                <BodyText md="0 0 0 .75rem">Handpacked with Care</BodyText>
                            </FlexBox>
                            <WhiteButton
                                onClick={() => {
                                    setModalIndex(1);
                                    setShippingModalIsOpen(true);
                                }}
                            >
                                Learn More
                            </WhiteButton>
                        </FlexBox>
                    </div>
                    <Image
                        className="blur"
                        alt="blur"
                        fluid={{
                            ...getImage(productImages, 'product-origins-blur'),
                            sizes: '(min-width: 1px) 10vw, 135px',
                        }}
                    />
                </Col>
            </Row>
            <ShippingModal
                shippingModalIsOpen={shippingModalIsOpen}
                setShippingModalIsOpen={setShippingModalIsOpen}
                modalIndex={modalIndex}
                setModalIndex={setModalIndex}
            />
        </ShippingInfoWrapper>
    );
};

export default ShippingInfo;
