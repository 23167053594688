import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import { breakpoint } from '../../../constants/Breakpoint';
import { getMetafieldNode } from '~/helpers';
import { getShopMetafields } from '~/helpers/requests/metafield-requests';
import { Heading, BodyTextAlt, BodyText } from '~/components/.base/headings';
import { RedButton, WhiteButton } from '~/components/.base/buttons';
import { Row, Col } from '~/components/.base/containers';
import video_thumb from '~/images/pdp/img-big.jpg';
import PlayButtonIcon from '~/images/icons/pdp/play-btn.svg';

const expandPDP = keyframes`
	from {
		top: 0px;
		left: 0px;
		border: 1px solid #fff;
	}
	50% {
		top: -50px;
		left: -50px;
		width: calc(100% + 100px);
		height: calc(100% + 100px);
		opacity: 0;
	}
	to {
		top: -50px;
		left: -50px;
		width: calc(100% + 100px);
		height: calc(100% + 100px);
		opacity: 0;
	}
`;

const SectionWrapper = styled.div`
    width: 90%;
    max-width: 950px;
    margin: auto;
    padding-bottom: 100px;
    .video-cont {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 64.7%;
        iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
            &.playing {
                z-index: 1;
            }
        }
        .thumb {
            transition: opacity 0.3s linear;
            &.playing {
                opacity: 0;
                display: none;
            }
        }
        button {
            width: 60px;
            height: 60px;
            position: absolute;
            left: calc(50% - 30px);
            top: calc(50% - 30px);
            padding: 0;
            .animation-1,
            .animation-2 {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: transparent;
                border-radius: 50%;
                z-index: 0;
            }
            &:hover {
                .animation-1,
                .animation-2 {
                    animation: ${expandPDP} 3s linear infinite;
                }
                .animation-2 {
                    animation-delay: 0.75s;
                }
            }
        }
    }
    &.mobile {
        padding-bottom: 50px;
        flex-direction: column;
        .recipe {
            width: 100%;
            height: 60px;
        }
        .video-cont {
            img {
                width: 100%;
                border-radius: 30px;
            }
        }
        iframe {
            opacity: 0;
            &.playing {
                opacity: 1;
            }
        }
    }
    @media ${breakpoint.maxLg} {
        flex-direction: column;
        .recipe {
            width: 100%;
            max-width: 400px;
            height: 60px;
        }
        .video-cont {
            img {
                width: 100%;
                border-radius: 30px;
            }
        }
        iframe {
            opacity: 0;
            &.playing {
                opacity: 1;
            }
        }
    }
`;

const CookingInstructions = ({ showStickyMenu, cookingRef, videoUrl }) => {
    const [playingVideo, setPlayingVideo] = useState(false);
    const [recipeLink, setRecipeLink] = useState(false);
    useEffect(() => {
        getShopMetafields()
            .then(res => {
                setRecipeLink(
                    JSON.parse(
                        getMetafieldNode(res.data.data.shop.metafields.edges, 'recipe_pdf')
                    )[0].src
                );
            })
            .catch(err => console.log(err));
    }, []);
    return (
        <SectionWrapper>
            <div
                id="cooking-instructions"
                style={{ position: 'relative', top: '-20vh' }}
                tabIndex="-1"
            />
            <Row ref={cookingRef}>
                {showStickyMenu ? <Col className="sticky-menu-col" size={1}></Col> : null}
                <Col size={4}>
                    <Row className="cooking-instructions">
                        <Col size={1} pd="1rem">
                            <BodyTextAlt md="0 0 .5rem" color="#d4212c">
                                Cooking Instructions
                            </BodyTextAlt>
                            <Heading md="0 0 1.5rem">
                                Learn how to cook our delicious Maine seafood products easily at
                                home!
                            </Heading>
                            <BodyText md="0 0 1.5rem">
                                Download our Recipe & Product Guide below for recipes, preparation,
                                and cooking instructions on Cousins Maine Lobster seafood products.
                            </BodyText>

                            {recipeLink ? (
                                <a
                                    href={recipeLink}
                                    download="CML_Recipe_Kit"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <RedButton className="recipe">Download Recipe PDF</RedButton>
                                </a>
                            ) : null}
                        </Col>
                        {videoUrl ? (
                            <Col size={1} pd="1rem">
                                <div className="video-cont">
                                    <img
                                        src={video_thumb}
                                        alt="thumb"
                                        className={`thumb ${playingVideo ? 'playing' : ''}`}
                                    />
                                    {!playingVideo ? (
                                        <WhiteButton
                                            onClick={() => {
                                                setPlayingVideo(true);
                                            }}
                                        >
                                            <PlayButtonIcon />
                                            <span className="animation-1" />
                                            <span className="animation-2" />
                                        </WhiteButton>
                                    ) : null}
                                    <iframe
                                        title="Cooking Instruction"
                                        width="100%"
                                        height="100%"
                                        src={`${videoUrl}${playingVideo ? '?autoplay=1' : ''}`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        className={playingVideo ? 'playing' : ''}
                                    ></iframe>
                                </div>
                            </Col>
                        ) : null}
                    </Row>
                </Col>
            </Row>
        </SectionWrapper>
    );
};

export default CookingInstructions;
