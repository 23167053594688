import React, { useEffect, useContext, useRef } from 'react';
import StoreContext from '~/layouts/StoreContext';
import { Link } from 'gatsby';

import { getShopifyImageTransformedSrc } from '../../../helpers';
import Modal from '~/components/.base/modal';
import { Heading, BodyText, BodyTextAlt } from '~/components/.base/headings';
import { RedButton, WhiteButton } from '~/components/.base/buttons';
import { Grid, Col, FlexBox } from '~/components/.base/containers';
import './UpsellModal.scss';
import TagIcon from '~/images/icons/tag.svg';
import styled from 'styled-components';

const DiscountCode = styled.div`
  width: fit-content;
  margin: 0 0 15px;
  display: flex;
  align-items: center;
  margin: auto;
  p {
    margin: 0;
  }
  svg {
    width: 17px;
    margin-right: 5px;
    fill: #555;
  }
`;

const UpsellModal = ({
  upsellProducts,
  setUpsellProducts,
  subscribing,
  subscriptionInterval,
  upsellModalIsOpen,
  setUpsellModalIsOpen,
  product,
  subscriptionProduct,
  selectedVariant,
}) => {
  const {
    store: {
      client,
      checkout,
      checkout: { lineItems, discountApplications },
      discountType,
      discountAmount,
      linkDiscountCode,
    },
    addVariantToCart,
    removeLineItem,
    openCart,
  } = useContext(StoreContext);
  const isCartUpdating = useRef(false);
  useEffect(() => {
    setUpsellProducts(prods => prods.map(p => ({ ...p, hasAddedToCart: false })));
  }, [upsellModalIsOpen, setUpsellProducts]);
  const handleAddToCart = product => {
    isCartUpdating.current = true;

    const varId = product.variants.edges[0].node.id;
    let props = [];
    if (subscribing) {
      props.push({
        key: 'subscription_interval',
        value: subscriptionInterval,
      });
    }
    props.push({
      key: 'upsell_product',
      value: 'true',
    });
    props.push({
      key: 'upsell_discount',
      value: product.discount_amount,
    });
    props.push({
      key: 'discount_type',
      value: product.upsell_type,
    });
    props.push({
      key: 'base_product_for_upsell_var_id',
      value: selectedVariant.shopifyId,
    });

    addVariantToCart(varId, 1, props).then(res => {
      setUpsellProducts(prods =>
        prods.map(p => ({
          ...p,
          hasAddedToCart: p.variants.edges[0].node.id === varId ? true : p.hasAddedToCart,
        }))
      );

      isCartUpdating.current = false;
    });
  };
  const handleRemoveFromCart = product => {
    isCartUpdating.current = true;

    const varId = product.variants.edges[0].node.id;
    const newLineItem = lineItems.find(item => item.variant.id === varId);

    if (newLineItem) {
      const newLineItemID = newLineItem.id;

      removeLineItem(client, checkout.id, newLineItemID).then(res => {
        setUpsellProducts(prods =>
          prods.map(p => ({
            ...p,
            hasAddedToCart: p.variants.edges[0].node.id === varId ? false : p.hasAddedToCart,
          }))
        );

        isCartUpdating.current = false;
      });
    }
  };

  return (
    <Modal
      open={upsellModalIsOpen}
      onClose={() => {
        setUpsellModalIsOpen(false);
        openCart();
      }}
      ariaLabelledby="upsell-modal-title"
      top="0"
    >
      <Grid className="upsell-modal-body">
        <FlexBox className="modal-upper" align="flex-end">
          <Heading id="upsell-modal-title" md="0">
            Extra savings, no additional shipping
          </Heading>
          <BodyText md="0 0 0 1rem">(when you ship these items to the same address)</BodyText>
        </FlexBox>
        <div className={`upsell-grid ${upsellProducts.length >= 4 ? 'full-grid' : ''}`}>
          {upsellProducts
            .filter(
              prod =>
                prod.title &&
                !(prod.discount_amount === '100' && prod.upsell_type === 'Percent Discount')
            )
            .map(prod => {
              if (
                (subscribing && !prod.title.includes('Subscription')) ||
                (!subscribing && prod.title.includes('Subscription'))
              )
                return null;
              var price, compareAtPrice, savings, discountSavings;
              compareAtPrice = subscribing
                ? (Number(prod.variants.edges[0].node.priceV2.amount) * 10) / 9
                : Number(prod.variants.edges[0].node.priceV2.amount);
              if (prod.upsell_type === 'Percent Discount') {
                price = (compareAtPrice * (100 - Number(prod.discount_amount))) / 100;
                if (subscribing) {
                  price = price * 0.9;
                }
                savings = (compareAtPrice - price).toFixed(2);
              } else {
                price = compareAtPrice - Number(prod.discount_amount);
                if (subscribing) {
                  price = price * 0.9;
                }
                savings = Number(prod.discount_amount).toFixed(2);
              }
              if (discountType === 'Percentage') {
                discountSavings = price - (price * (100 - discountAmount)) / 100;
                price = (price * (100 - discountAmount)) / 100;
              }
              return (
                <Col
                  key={prod.variants.edges[0].node.id}
                  size={1}
                  pd="30px"
                  className="product-thumb"
                >
                  <div className={`img-cont ${prod.hasAddedToCart ? 'added' : ''}`}>
                    <img
                      src={getShopifyImageTransformedSrc(
                        prod.media.edges[0].node.previewImage.originalSrc,
                        '_300x'
                      )}
                      alt={prod.title}
                    />
                  </div>
                  <BodyText style={{ fontSize: '16px' }}>{prod.title}</BodyText>
                  <div className="savings">
                    <BodyTextAlt md="0 0 1px">
                      <span class="sr-only">Current price:</span>
                      <span>{`$${price.toFixed(2)} `}</span>
                      <span class="sr-only">Original price:</span>
                      <strike>{`$${compareAtPrice.toFixed(2)}`}</strike>
                    </BodyTextAlt>

                    <BodyTextAlt
                      md="0 0 1px"
                      color="#d4212c"
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Save ${savings}
                    </BodyTextAlt>
                    {discountSavings ? (
                      <DiscountCode>
                        <TagIcon />
                        <BodyTextAlt color="#d4212d" md="0">
                          {discountApplications?.length
                            ? discountApplications[0].code
                            : linkDiscountCode}
                          : -$
                          {discountSavings.toFixed(2)}
                        </BodyTextAlt>
                      </DiscountCode>
                    ) : null}
                    {prod.hasAddedToCart ? (
                      <RedButton
                        md="20px auto 0"
                        onClick={() => {
                          if (!isCartUpdating.current) {
                            handleRemoveFromCart(prod)}
                          }
                        }
                        className="added-btn"
                      >
                        <span className="reg-text">Added</span>
                        <span className="hover-text">Remove</span>
                      </RedButton>
                    ) : (
                      <WhiteButton
                        md="20px auto 0"
                        onClick={() => {
                          if (!isCartUpdating.current) {
                            handleAddToCart(prod)
                          }
                        }}
                      >
                        Add{' '}
                        <span
                          style={{
                            fontSize: '20px',
                            marginLeft: '.25rem',
                            marginRight: '-.25rem',
                          }}
                        >
                          +
                        </span>
                      </WhiteButton>
                    )}
                  </div>
                </Col>
              );
            })}
        </div>
        <FlexBox justify="flex-end" className="modal-footer">
          <WhiteButton
            onClick={() => {
              setUpsellModalIsOpen(false);
              openCart();
            }}
          >
            Continue Shopping
          </WhiteButton>
          <RedButton as={Link} to="/cart" md="0 0 0 1rem">
            Checkout
          </RedButton>
        </FlexBox>
      </Grid>
    </Modal>
  );
};

export default UpsellModal;
