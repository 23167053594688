import React from 'react';
import Modal from '~/components/.base/modal';
import { Heading, BodyText } from '~/components/.base/headings';
import { UnderscoreLink } from 'components/.base/links';
import { RedButton, WhiteButton } from '~/components/.base/buttons';
import { Grid, FlexBox } from '~/components/.base/containers';
import './BeforeAddGiftCardToCartModal.scss';

const BeforeAddGiftCardToCartModal = ({ isAGiftCard, beforeAddGiftCardToCartModal, addToCart }) => {
    const { isModalOpen, handleIsModalOpen } = beforeAddGiftCardToCartModal;
    return (
        isAGiftCard && (
            <Modal
                open={isModalOpen}
                onClose={() => {
                    handleIsModalOpen(false);
                }}
                ariaLabelledby="beforeAddGiftCardToCart-modal-title"
                top="0"
                modalMaxWidth="650px"
            >
                <Grid className="beforeAddGiftCardToCart-modal-body">
                    <FlexBox className="modal-upper" align="flex-end">
                        <Heading id="beforeAddGiftCardToCart-modal-title" md="0">
                            Online Shop E-Gift Cards
                        </Heading>
                    </FlexBox>
                    <div className={`beforeAddGiftCardToCart-body`}>
                        <BodyText>
                            Please note, online shop gift cards are redeemable only in our online
                            shop. If you wish to purchase a gift card for a truck or restaurant
                            location, please contact your local truck or restaurant through the
                            contact info found at{' '}
                            <UnderscoreLink href="www.cousinsmainelobster.com/locations">
                                www.cousinsmainelobster.com/locations
                            </UnderscoreLink>
                            .
                        </BodyText>
                        <BodyText>
                            If you wish to purchase an online shop e-gift card, please choose
                            "Continue" below.
                        </BodyText>
                    </div>
                    <FlexBox justify="flex-end" className="modal-footer">
                        <WhiteButton
                            onClick={() => {
                                handleIsModalOpen(false);
                            }}
                        >
                            Cancel
                        </WhiteButton>
                        <RedButton
                            onClick={() => {
                                addToCart();
                                handleIsModalOpen(false);
                            }}
                            md="0 0 0 1rem"
                        >
                            Continue
                        </RedButton>
                    </FlexBox>
                </Grid>
            </Modal>
        )
    );
};

export default BeforeAddGiftCardToCartModal;
