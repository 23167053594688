import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { chunk } from 'lodash';
import { Link } from 'gatsby';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';

import { breakpoint } from '../../../constants/Breakpoint';
import { getShopifyImageTransformedSrc, getMetafield } from '../../../helpers';
import { Heading, HeadingLarge, BodyTextAlt } from '~/components/.base/headings';
import { Container, FlexBox, Row, Col } from '~/components/.base/containers';
import Slideshow from '~/components/.base/slideshow';
import no_image from '~/images/collectionList/noimage.jpeg';

const SectionWrapper = styled(Container)`
    .recommended-products {
        // width: 90%;
        // max-width: 1286px;
        padding-top: 141px;
        padding-bottom: 74px;
    }
    .recommended-products-title {
        &.mobile {
            display: none;
        }
    }
    .slideshow-col {
        max-width: 85%;
        &.mobile {
            width: 100%;
            display: none;
        }
    }
    .carousel.carousel-slider {
        padding-left: 2rem;
        overflow: visible;
        .slide {
            .price {
                margin-top: 28px;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 1px;
                color: #d4212c;
                font-family: Montserrat;
                text-align: left;
            }
            .product-grid {
                list-style: none;
                max-width: 100%;
                margin-top: 0;
                padding-bottom: 0;
            }
            .product-tile {
                .img-cont--default {
                    position: relative;
                    img {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center center;
                    }
                }
                ${BodyTextAlt} {
                    margin: 20px 0 3px;
                    transition: color 0.2s linear;
                }
                &:hover {
                    ${BodyTextAlt} {
                        color: #d4212c;
                    }
                }
            }
        }
        .prev-next {
            bottom: -5px;
            &.prev {
                left: -256px;
            }
            &.next {
                left: -216px;
            }
        }
        .control-dots {
            bottom: -5px;
            width: fit-content;
            left: -166px;
            .circle {
                .inner {
                    background-color: rgba(92, 101, 102, 0.7);
                }
                &.selected .inner {
                    background-color: #d4212c;
                }
            }
        }
        @media ${breakpoint.maxXl} {
            flex-direction: column;
            .prev-next {
                bottom: 60px;
                &.prev {
                    left: -100px;
                }
                &.next {
                    left: -60px;
                }
            }
        }
    }
    @media ${breakpoint.maxMd} {
        .recommended-products {
            flex-direction: column;
        }
        .recommended-products-title {
            margin-bottom: 1.5rem;
            &.desktop {
                display: none;
            }
            &.mobile {
                display: block;
                text-align: center;
            }
        }
        .slideshow-col {
            max-width: 400px;
            margin: auto;
            &.desktop {
                display: none;
            }
            &.mobile {
                display: block;
            }
        }
        .slideshow {
            padding-bottom: 60px;
            .carousel.carousel-slider {
                padding: 0;
                .prev-next {
                    bottom: -30px;
                    &.prev {
                        left: 15px;
                    }
                    &.next {
                        left: 55px;
                    }
                }
                .control-dots {
                    bottom: -30px;
                    width: fit-content;
                    left: 115px;
                }
            }
        }
    }
    @media ${breakpoint.maxSm} {
        .slideshow-col {
            max-width: 100%;
        }
        .slideshow {
            .carousel.carousel-slider {
                .prev-next {
                    &.prev {
                        left: 0px;
                    }
                    &.next {
                        left: 40px;
                    }
                }
                .control-dots {
                    left: 100px;
                }
            }
        }
    }
`;

const ProductItem = ({ productItem }) => {
    const {
        node: { images, title, handle, variants },
    } = productItem;
    const imgSrc =
        images && images.edges.length
            ? images.edges[0].node.transformedSrc
                ? images.edges[0].node.transformedSrc
                : getShopifyImageTransformedSrc(images.edges[0].node.originalSrc, '_400x')
            : null;
    return (
        <FlexBox
            as={Link}
            to={`/products/${handle}`}
            direction="column"
            align="center"
            className="product-tile"
        >
            <div className="img-cont img-cont--default">
                {imgSrc ? (
                    <LazyLoad height={300}>
                        <img src={imgSrc} alt="" />
                    </LazyLoad>
                ) : (
                    <img src={no_image} alt="" />
                )}
            </div>
            <div className="details">
                <BodyTextAlt as="h3">{title}</BodyTextAlt>
                <Heading as="p">
                    <span className="red">
                        {variants && variants.edges ? (
                            <Fragment>
                                <span className="dollar-sign">$</span>
                                {
                                  variants.edges[0].node.price.amount
                                  ? Number(variants.edges[0].node.price.amount).toFixed(2)
                                  : variants.edges[0].node.price
                                }
                            </Fragment>
                        ) : null}
                    </span>
                </Heading>
            </div>
        </FlexBox>
    );
};

const RecommendedProducts = ({ product, metafields, collection }) => {
    const [recommendedProductsArr, setRecommendedProductsArr] = useState(
        collection && collection.products
            ? collection.products.edges.filter(({ node }) => node.handle !== product.handle)
            : []
    );
    useEffect(() => {
        (async () => {
            const metafieldsRPVal = getMetafield(metafields, 'related_product');

            if (metafieldsRPVal) {
                const metafieldsRPArr = JSON.parse(metafieldsRPVal);
                let mfProducts = [];
                for (let item of metafieldsRPArr) {
                    if (item !== product.handle) {
                        const query = `
                          query {
                            productByHandle(handle: "${item}") {
                              images (first: 1) {
                                edges {
                                node {
                                  originalSrc
                                  transformedSrc(maxWidth: 400)
                                }
                                }
                              }
                              title
                              variants (first: 1) {
                                edges {
                                  node {
                                    price {
                                      amount
                                    }
                                  }
                                }
                              }
                            }
                          }
                        `;
                        const res = await axios.post(
                            `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-01/graphql.json`,
                            { query },
                            {
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/json',
                                    'X-Shopify-Storefront-Access-Token':
                                        process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
                                },
                            }
                        );

                        console.log('recommendedProducts res', res);

                        mfProducts.push({
                            node: {
                                ...res.data.data.productByHandle,
                                handle: item,
                            },
                        });
                    }
                }
                let newRPs = mfProducts.concat(collection.products.edges);
                setRecommendedProductsArr(newRPs);
            }
        })();
    }, [product, collection, metafields]);

    return recommendedProductsArr && recommendedProductsArr.length ? (
        <SectionWrapper>
            <Row className="recommended-products" justify="space-between">
                <Col size={1}>
                    <HeadingLarge as="h2" className="recommended-products-title desktop">
                        You <br />
                        might <br />
                        <span className="red">
                            also <br />
                            like
                        </span>
                    </HeadingLarge>
                    <HeadingLarge as="h2" className="recommended-products-title mobile">
                        You might
                        <span className="red"> also like</span>
                    </HeadingLarge>
                </Col>
                <Col className="slideshow-col desktop" size={4}>
                    <Slideshow carouselLabel="recommended products">
                        {chunk(recommendedProductsArr, 3).map((productSubGroup, i) =>
                            i < 9 ? (
                                <ul className="product-grid" key={i}>
                                    {productSubGroup.map((product, i) => (
                                        <li key={i} role="group" aria-label="product">
                                            <ProductItem productItem={product} />
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                false
                            )
                        )}
                    </Slideshow>
                </Col>
                <Col className="slideshow-col mobile" size={4}>
                    <Slideshow carouselLabel="recommended products">
                        {collection && recommendedProductsArr
                            ? recommendedProductsArr
                                  .slice(0, 3)
                                  .map((product, i) => (
                                      <ProductItem key={i} productItem={product} />
                                  ))
                            : null}
                    </Slideshow>
                </Col>
            </Row>
        </SectionWrapper>
    ) : null;
};

export default RecommendedProducts;
