import React, { useState, useEffect, useContext, useMemo, Fragment } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import styled, { css } from 'styled-components';
import StoreContext from '~/layouts/StoreContext';
import {
  getAssociationsByVariantId,
  getProductVariantInventory,
} from '~/helpers/requests/association-requests';
import { getShopifyProduct, getUpsellById } from '~/helpers/requests/upsell-requests';
import { getQuantityDiscounts } from '~/helpers/requests/quantity-discount-requests';
import { getMetafield, getImage } from '~/helpers';
import { FlexBox, BackgroundDiv } from '~/components/.base/containers';
import { BodyText, BodyTextAlt, HeadingLarge } from '~/components/.base/headings';
import { StickyATC, UpsellModal, SubscriptionModal } from '~/components/ProductPage';
import ProductForm from './ProductForm';
import './ProductDetails.scss';
import { breakpoint } from '../../../constants/Breakpoint';
import TagIcon from '~/images/icons/tag.svg';
import { verifyDiscount } from '~/helpers/requests/discount-code-requests';
import { useChangeVariant } from '~/hooks/product';
import AnchorLink from 'react-anchor-link-smooth-scroll';
const SectionWrapper = styled.div`
  margin-top: 20px;
  position: sticky;
  top: 80px;
  height: fit-content;
  width: 100%;
  max-width: 514px;
  .red-product-title {
    color: #e7001f;
  }
  .breadcrumbs {
    p {
      margin: 0;
    }
    .spacer {
      margin: 0 0.5rem;
    }
    a,
    span {
      line-height: 1;
    }
    margin-bottom: 1rem;
  }
  h1 {
    margin-bottom: 40px;
  }
  .product-info {
    .product-price {
      font-family: 'Tiempos Text';
      font-size: 48px;
      font-weight: 500;
      line-height: 0.92;
      color: #e7001f;
      margin-right: 20px;

      .dollar-sign {
        font-size: 16px;
        position: relative;
        bottom: 22px;
      }
      .price {
        margin-left: 2px;
      }
    }
    .product-desc {
      line-height: 1.5;
    }
    .unit-price {
      white-space: nowrap;
      margin: 15px 1rem 15px 0;
    }
  }
  .product-form {
    padding: 1rem 0;
    width: 550px;
    max-width: 100%;
    .label {
      margin-left: 20px;
      margin-bottom: 7px;
    }
    select {
      width: 100%;
      height: 60px;
      border-radius: 30px;
      transition: border 0.2s linear;
      cursor: pointer;
      &.is-invalid,
      &:focus {
        border: 1px solid #d4212c !important;
        color: #2e3233;
      }
      @media ${breakpoint.md} {
        &:hover {
          border: 1px solid #5c6566;
          &::placeholder {
            color: #5c6566;
          }
        }
      }
    }
    .product-details-row {
      .input {
        width: 100%;
        margin-bottom: 0px;
      }
    }
    .quantity-cont {
      margin-bottom: 0px;
      .label {
        display: block;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #5c6566;
        font-family: Montserrat, sans-serif;
        height: auto;
        margin-bottom: 7px;
      }
      .quantity {
        letter-spacing: 1px;
        font-size: 12px;
        font-weight: 500;
        color: #5c6566;
        font-family: Montserrat, sans-serif;
        min-width: 125px;
        width: 100%;
        height: 60px;
        border-radius: 30px;
        border: solid 1px #e2e2e4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: border 0.2s linear;
        background-color: #fff;
        .quantity__adjust {
          padding: 0rem 1rem;
          height: calc(100% + 2px);
          display: flex;
          align-items: center;
          outline: none;
          border-color: transparent;
          border-style: solid;
          margin: -1px 0;
          &:first-child {
            border-width: 1px 0 1px 1px;
            border-radius: 30px 0 0 30px;
          }
          &:last-child {
            border-width: 1px 1px 1px 0;
            border-radius: 0 30px 30px 0;
          }
          &:focus {
            &:not(:disabled) {
              border-color: #d4212c;
            }
          }
          @media ${breakpoint.md} {
            &:hover {
              &:not(:disabled) {
                border-color: #5c6566;
              }
            }
          }
        }
      }
    }
    .subscription-cont {
      margin-bottom: 0px;
      @media ${breakpoint.lg} {
        &.subscription-select {
          margin-top: 10px;
        }
      }
      .label {
        margin-bottom: 7px;
        margin-left: 20px;
        letter-spacing: 1px;
        svg {
          margin-right: 3px;
        }
      }
      .sub-info {
        cursor: pointer;
        position: relative;
        left: 14px;
        g {
          fill: #5c6566;
        }
      }
      button:hover {
        .sub-info g {
          fill: #fff;
        }
      }
      button .sub-cta svg {
        margin-right: 3px;
      }
      .sub-info:hover + .sub-popover {
        display: block;
      }
      .sub-popover {
        text-align: left;
        border-radius: 10px;
        font-family: TimesNewRomanPSMT;
        line-height: 1.38;
        font-size: 16px;
        display: none;
        width: 390px;
        padding: 14px 20px 25px;
        position: absolute;
        background: #5c6566;
        color: #fff;
        bottom: 45px;
        right: -70px;
        text-transform: none;
        font-weight: 300;
      }
      .sub-popover-clicked {
        text-align: left;
        border-radius: 10px;
        font-family: TimesNewRomanPSMT;
        line-height: 1.38;
        font-size: 16px;
        display: none;
        width: 390px;
        padding: 14px 20px 25px;
        position: absolute;
        background: #5c6566;
        color: #fff;
        bottom: 30px;
        right: -86px;
        text-transform: none;
        font-weight: 300;
        @media ${breakpoint.maxXs} {
          width: 300px;
        }
      }
      .aux-message {
        top: -5px;
        right: 20px;
        .info {
          cursor: pointer;
          &:hover {
            g {
              fill: #d4212c;
            }
          }
          g {
            fill: #5c6566;
          }
        }

        .info:hover + .sub-popover-clicked {
          display: block;
        }
      }
      select {
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        &::-ms-expand {
          display: none;
        }
        border-radius: 30px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border: 1px solid #e2e4e4;
        height: 60px;
        width: calc(100% - 50px);
        padding: 0px 25px;
        color: #5c6566;
        background-color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
        option {
          text-transform: none;
        }
        &.is-invalid,
        &:focus {
          border: 1px solid #d4212c !important;
          color: #2e3233;
        }
        @media ${breakpoint.md} {
          &:hover {
            border: 1px solid #5c6566;
            &::placeholder {
              color: #5c6566;
            }
          }
        }
      }
      .close {
        width: 50px;
        height: 60px;
        padding: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: 0px;
      }
      .arrow-down {
        position: absolute;
        width: 16px;
        height: 16px;
        right: 60px;
        top: 45px;
        g {
          fill: #d4212c;
        }
      }
    }
    .date-cont {
      border: 1px solid #e2e2e4;
      border-radius: 30px;
      width: fit-content;
      .MuiPickersStaticWrapper-staticWrapperRoot {
        border-radius: 30px;
      }
    }
  }
  #popover .popover-inner {
    width: 390px;
    background-color: #5c6566;
    padding: 15px 20px 15px;
    p {
      font-size: 16px;
      color: #fff;
      margin: 0px;
    }
  }
  @media ${breakpoint.maxMd} {
    padding: 0 20px;
    .product-details-row {
      flex-direction: column;
      > div {
        width: 100%;
      }
      &.single {
        .quantity-cont {
          margin-bottom: 10px;
        }
      }
      .order-2-mobile {
        order: 2;
      }
    }
    h1 {
      margin: 0 0 25px;
    }
    .product-price,
    .savings {
      margin: 0 5px 25px 0;
    }
  }
  @media ${breakpoint.maxXs} {
    #popover .popover-inner {
      width: 100%;
    }

    .sub-popover {
      width: 300px;
    }

    .sub-popover-clicked {
      width: 300px;
    }
  }
  @media screen and (max-width: 950px) {
    .sub-popover,
    .sub-popover-clicked {
      right: 16px !important;
    }
  }
`;
const DiscountCode = styled.div`
  background-color: rgba(212, 33, 45, 0.2);
  width: fit-content;
  padding: 5px;
  margin: 0 5px 15px 0;
  display: flex;
  align-items: center;
  p {
    white-space: nowrap;
  }
  svg {
    width: 17px;
    margin-right: 5px;
    fill: #555;
  }
`;
const ProductDetails = ({
  locationSearch,
  product,
  subscriptionProduct,
  collections,
  metafields,
  title,
  subtitle,
  backgroundImages,
  sourceCollection,
}) => {
  const { store, openCart, addVariantToCart } = useContext(StoreContext);
  const {
    checkout: { discountApplications },
    discountType,
    discountAmount,
    linkDiscountCode,
  } = { ...store };

  const [selectedVariant, setSelectedVariant] = useState(product.variants[0]);
  const [selectedSubscriptionVariant, setSelectedSubscriptionVariant] = useState(
    subscriptionProduct ? subscriptionProduct.variants[0] : null
  );
  const [selectedRegularVariant, setSelectedRegularVariant] = useState(product.variants[0]);
  const [isSubscription, setIsSubscription] = useState(null);
  const [subscribing, setSubscribing] = useState(false);
  const [subscriptionInterval, setSubscriptionInterval] = useState('');
  const [upsellProducts, setUpsellProducts] = useState([]);
  const [upsellModalIsOpen, setUpsellModalIsOpen] = useState(false);
  const [subscriptionModalIsOpen, setSubscriptionModalIsOpen] = useState(false);
  const [quantity, setQuantity] = useState(product.handle === 'live-lobsters-minimum-2' ? 2 : 1);
  const [quantityDiscounts, setQuantityDiscounts] = useState([]);
  const [activeQuantityDiscount, setActiveQuantityDiscount] = useState(null);
  const [quantityDiscountInEffect, setQuantityDiscountInEffect] = useState(false);
  const [availabilitiesPerVariant, setAvailabilitiesPerVariant] = useState([]);
  const [availableForSale, setAvailableForSale] = useState(product.availableForSale);
  const [loadingAvailability, setLoadingAvailability] = useState(true);
  const [varAvailableQuantity, setVarAvailableQuantity] = useState(0);

  const [isAGiftCard] = useState(product.variants[0].sku.includes('GIFT'));
  const [giftCardName, setGiftCardName] = useState('');
  const [giftCardSenderName, setGiftCardSenderName] = useState('');
  const [giftCardMessage, setGiftCardMessage] = useState('');
  const [giftCardRecipient, setGiftCardRecipient] = useState('');
  const [confirmGiftCardRecipient, setConfirmGiftCardRecipient] = useState('');
  const [giftCardSender, setGiftCardSender] = useState('');
  const [confirmGiftCardSender, setConfirmGiftCardSender] = useState('');
  const [giftCardNotificationDate, setGiftCardNotificationDate] = useState(null);
  const [giftCardErrors, setGiftCardErrors] = useState({});
  const [hasDiscount, setHasDiscount] = useState(false);

  useEffect(() => {
    // gets and caches availablities for all variants based on associations, etc.
    (async () => {
      if (product.shopifyId) {
        let availabilityArr = [];
        try {
          for (let v of product.variants) {
            const associationData = await getAssociationsByVariantId(v.shopifyId);

            let associationQuantities = [];
            if (associationData.data.length) {
              for (let assn of associationData.data) {
                const associatedVariantInventoryData = await getProductVariantInventory(
                  btoa(assn.associated_variant_id)
                );
                const { id, quantityAvailable } = associatedVariantInventoryData.data.data.node;
                associationQuantities.push({
                  id,
                  quantityAvailable,
                });
              }
            }
            availabilityArr.push({
              variantId: v.shopifyId,
              availableForSale: v.availableForSale,
              associationQuantities,
            });
          }

          setLoadingAvailability(false);
          setAvailabilitiesPerVariant(availabilityArr);
        } catch (err) {
          setLoadingAvailability(false);
          availabilityArr = product.variants?.map(v => {
            return {
              variantId: v.shopifyId,
              availableForSale: v.availableForSale,
              associationQuantities: [],
            };
          });
          setAvailabilitiesPerVariant(availabilityArr);
          console.error(err);
        }
      }
    })();
  }, [product]);

  useEffect(() => {
    // loads variant availability based on cached availabilities and selected variant
    if (availabilitiesPerVariant.length && selectedVariant.id && selectedRegularVariant.id) {
      const currVarAvailability = availabilitiesPerVariant.find(item =>
        subscribing
          ? item.variantId === selectedRegularVariant.shopifyId
          : item.variantId === selectedVariant.shopifyId
      );
      if (currVarAvailability) {
        let isAvailable = currVarAvailability.availableForSale;
        for (let association of currVarAvailability.associationQuantities) {
          if (association.quantityAvailable < 1) {
            isAvailable = false;
          }
        }
        setAvailableForSale(isAvailable);
        setVarAvailableQuantity(currVarAvailability.quantityAvailable);
      }
    }
  }, [availabilitiesPerVariant, selectedVariant, selectedRegularVariant, subscribing]);

  useEffect(() => {
    // correctly syncs variants when subscription is active
    if (subscriptionProduct) {
      const tempVariant = product.variants.find(p => p.shopifyId === selectedVariant.shopifyId);
      if (subscribing && tempVariant) {
        const varIndex = product.variants.indexOf(tempVariant);
        if (varIndex > -1) {
          setSelectedVariant(subscriptionProduct.variants[varIndex]);
          setSelectedSubscriptionVariant(subscriptionProduct.variants[varIndex]);
          setSelectedRegularVariant(product.variants[varIndex]);
        }
      }
      const subTempVariant = subscriptionProduct.variants.find(
        p => p.shopifyId === selectedVariant.shopifyId
      );
      if (!subscribing && subTempVariant) {
        const varIndex = subscriptionProduct.variants.indexOf(subTempVariant);
        if (varIndex > -1) {
          setSelectedVariant(product.variants[varIndex]);
          setSelectedSubscriptionVariant(subscriptionProduct.variants[varIndex]);
          setSelectedRegularVariant(product.variants[varIndex]);
        }
      }
    }
  }, [subscriptionProduct, subscribing, selectedVariant, product.variants]);

  useEffect(() => {
    if (metafields) {
      setIsSubscription(getMetafield(metafields, 'enable_subscription') ? true : false);
    }
  }, [metafields]);

  useEffect(() => {
    if (product && product.shopifyId) {
      getQuantityDiscounts(product.shopifyId)
        .then(res => {
          setQuantityDiscounts(res.data);
        })
        .catch(err => console.log(err));
    }
  }, [product]);

  useEffect(() => {
    var highestQuantityThreshold = 0,
      discountInEffect = false;
    for (let discount of quantityDiscounts) {
      if (discount.shopify_variant_id === selectedRegularVariant.shopifyId) {
        if (quantity >= discount.quantity_threshold) {
          discountInEffect = true;
          if (discount.quantity_threshold > highestQuantityThreshold) {
            setActiveQuantityDiscount(discount);
            highestQuantityThreshold = discount.quantity_threshold;
          }
        }
      }
    }
    setQuantityDiscountInEffect(discountInEffect);
  }, [quantity, quantityDiscounts, selectedRegularVariant]);

  useEffect(() => {
    (async () => {
      if (product.shopifyId && isSubscription !== null) {
        try {
          const upsellRes = await getUpsellById(product.shopifyId, 'PDP');
          if (upsellRes.data.length) {
            const upsellArr = [];
            for (let item of upsellRes.data) {
              const product = await getShopifyProduct(item.upsell_handle);
              const productData = product?.data?.data;

              if (productData && productData?.productByHandle?.totalInventory > 0) {
                upsellArr.push({
                  ...productData.productByHandle,
                  discount_amount: item.discount_amount,
                  upsell_type: item.upsell_type,
                });
              }
            }
            if (isSubscription) {
              for (let item of upsellRes.data) {
                const product = await getShopifyProduct(item.upsell_handle + '-subscription');
                const productData = product?.data?.data;

                if (productData && productData?.productByHandle?.totalInventory > 0) {
                  upsellArr.push({
                    ...productData.productByHandle,
                    discount_amount: item.discount_amount,
                    upsell_type: item.upsell_type,
                  });
                }
              }
            }
            setUpsellProducts(upsellArr);
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [product, isSubscription]);

  useEffect(() => {
    (async () => {
      if (linkDiscountCode && discountApplications && collections && product) {
        {
          try {
            const res = await verifyDiscount(
              linkDiscountCode ? linkDiscountCode : discountApplications[0].code,
              product.handle,
              collections.edges[0].node.handle
            );

            setHasDiscount(res.data.hasDiscount);
          } catch (e) {
            console.log('verifyDiscount error', e);
          }
        }
      }
    })();
  }, [linkDiscountCode, collections, product, discountApplications]);
  const discountedAmount = useMemo(() => {
    let amount = 0;
    if (isAGiftCard) {
      return amount;
    }

    if (discountType === 'Percentage') {
      if (quantityDiscountInEffect) {
        if (subscribing) {
          amount +=
            (((Number(discountAmount) / 100) * activeQuantityDiscount.price_per_unit * 9) / 10) *
            quantity;
        } else {
          amount +=
            (Number(discountAmount) / 100) * activeQuantityDiscount.price_per_unit * quantity;
        }
      } else {
        amount += (Number(discountAmount) / 100) * Number(selectedVariant.price.amount) * quantity;
      }
    } else if (discountType === 'Absolute') {
      amount += discountAmount;
    }
    return amount;
  }, [
    isAGiftCard,
    discountType,
    discountAmount,
    quantityDiscountInEffect,
    quantity,
    activeQuantityDiscount,
    selectedVariant,
  ]);

  const addToCart = async () => {
    let props = [];
    const isValidated = handleGiftCardValidate(props);
    if (isAGiftCard && !isValidated) return;
    if (isAGiftCard) {
      props.push({
        key: 'gift_message',
        value: giftCardMessage,
      });
      props.push({
        key: 'recipient_name',
        value: giftCardName,
      });
      props.push({
        key: 'recipient_email',
        value: giftCardRecipient,
      });
      props.push({
        key: 'sender_name',
        value: giftCardSenderName,
      });
      props.push({
        key: 'sender_email',
        value: giftCardSender,
      });
      props.push({
        key: 'delivery_date',
        value: `${
          giftCardNotificationDate.getMonth() + 1
        }-${giftCardNotificationDate.getDate()}-${giftCardNotificationDate.getFullYear()}`,
      });
    }
    if (subscribing) {
      props.push({
        key: 'regular_product_id',
        value: product.shopifyId,
      });
      props.push({
        key: 'regular_variant_id',
        value: selectedRegularVariant.shopifyId,
      });
      props.push({
        key: 'subscription_interval',
        value: subscriptionInterval,
      });
    }
    if (!subscribing && isSubscription && selectedSubscriptionVariant) {
      // NOTE: remove this until subscriptions are enabled again
      // props.push({
      //   key: 'subscription_variant_id',
      //   value: selectedSubscriptionVariant.shopifyId,
      // });
    }
    if (quantityDiscounts.length) {
      let applicableQuantityDiscounts = false,
        thresholdString = '',
        pricePerUnitString = '';
      for (let discount of quantityDiscounts) {
        if (discount.shopify_variant_id === selectedRegularVariant.shopifyId) {
          applicableQuantityDiscounts = true;
          thresholdString += `${discount.quantity_threshold},`;
          pricePerUnitString += `${discount.price_per_unit.toFixed(2)},`;
        }
      }
      if (applicableQuantityDiscounts) {
        props.push({
          key: 'quantity_discount_unit_price',
          value: pricePerUnitString.slice(0, pricePerUnitString.length - 1),
        });
        props.push({
          key: 'quantity_discount_threshold',
          value: thresholdString.slice(0, pricePerUnitString.length - 1),
        });
      }
    }
    const gaObj = {
      name: subscribing ? subscriptionProduct.title : product.title,
      id: selectedVariant.shopifyId,
      price: selectedVariant.price.amount,
      brand: 'CML',
      variant: product.variants[0].title,
      quantity,
    };
    addVariantToCart(selectedVariant.shopifyId, quantity, props, gaObj).then(res => {
      window?.attentive?.analytics?.addToCart({
        items: [
          {
            productId: product.shopifyId,
            productVariantId: selectedVariant.shopifyId,
            name: product.title,
            productImage: product.images[0].originalSrc,
            category: 'CML',
            price: {
              value: selectedVariant.price.amount,
              currency: 'USD',
            },
            quantity: quantity,
          },
        ],
      });
      if (upsellProducts.length) {
        let hasFreeItem = false;
        for (let product of upsellProducts) {
          if (product.discount_amount === '100' && product.upsell_type === 'Percent Discount') {
            if (product.title.includes('Subscription')) {
              continue;
            }
            hasFreeItem = true;
            let props = [];
            props.push({
              key: 'upsell_product',
              value: 'true',
            });
            props.push({
              key: 'upsell_discount',
              value: product.discount_amount,
            });
            props.push({
              key: 'discount_type',
              value: product.upsell_type,
            });
            props.push({
              key: 'base_product_for_upsell_var_id',
              value: selectedVariant.shopifyId,
            });
            addVariantToCart(product.variants.edges[0].node.id, quantity, props).then(res => {
              setUpsellModalIsOpen(true);
            });
          }
        }
        if (!hasFreeItem) {
          setUpsellModalIsOpen(true);
        }
      } else {
        openCart();
      }
    });
  };

  const handleGiftCardValidate = props => {
    // true = pass, false = fail (writing this down bc i'm dumb)
    setGiftCardErrors({});
    const errors = {};
    if (!giftCardName) {
      errors.giftCardName = 'Required';
    }
    if (!giftCardSenderName) {
      errors.giftCardSenderName = 'Required';
    }
    if (!giftCardRecipient) {
      errors.giftCardRecipient = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(giftCardRecipient)) {
      errors.giftCardRecipient = 'Invalid email address';
    }
    if (!giftCardSender) {
      errors.giftCardSender = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(giftCardSender)) {
      errors.giftCardSender = 'Invalid email address';
    }
    if (giftCardSender !== confirmGiftCardSender) {
      errors.confirmGiftCardSender = 'Emails must match';
    }
    if (giftCardRecipient !== confirmGiftCardRecipient) {
      errors.confirmGiftCardRecipient = 'Emails must match';
    }
    if (
      !errors.giftCardName &&
      !errors.giftCardSenderName &&
      !errors.giftCardSender &&
      !errors.giftCardRecipient &&
      !errors.confirmGiftCardSender &&
      !errors.confirmGiftCardRecipient
    )
      return true;
    setGiftCardErrors(errors);
    return false;
  };

  const separate = product.title.split(' ');

  const middle = Math.ceil(separate.length / 2);
  const firstHalf = separate.splice(0, middle);
  const secondHalf = separate.splice(-middle);

  const reviewProductId = useMemo(() => {
    if (typeof window !== 'undefined') {
      return product.id.replace('Shopify__Product__', '').replace('gid://shopify/Product/', '');
    }
  }, [product.id]);

  const { handleOnVariantChange } = useChangeVariant({
    locationSearch,
    product,
    setSelectedVariant,
    setSelectedSubscriptionVariant,
    setSelectedRegularVariant,
    subscriptionProduct,
  });
  useEffect(() => {
    handleOnVariantChange();
  }, [handleOnVariantChange]);

  return (
    <Fragment>
      <SectionWrapper className="product-details">
        <FlexBox className="breadcrumbs" align="center">
          <Link to="/shop">
            <BodyText>Home</BodyText>
          </Link>
          <span className="spacer">/</span>
          {sourceCollection ? (
            <Link to={`/collections/${sourceCollection.handle}`}>
              <BodyText color="#d4212c">{sourceCollection.title}</BodyText>
            </Link>
          ) : (
            <Link
              to={`/collections/${
                collections && collections.edges[0] && collections.edges[0].node
                  ? `${collections.edges[0].node.handle}/`
                  : ''
              }`}
            >
              <BodyText color="#d4212c">
                {collections && collections.edges[0] && collections.edges[0].node
                  ? collections.edges[0].node.title
                  : ''}
              </BodyText>
            </Link>
          )}
        </FlexBox>
        <HeadingLarge>
          <span>
            {firstHalf.map((text, index) => {
              return text + ' ';
            })}
          </span>
          <span className="red-product-title">
            {secondHalf.map((text, index) => {
              if (index === secondHalf.length - 1) {
                return text;
              }
              return text + ' ';
            })}
          </span>
        </HeadingLarge>
        <FlexBox className="product-details-row product-info">
          <div>
            <FlexBox align="flex-end" style={{ height: 'fit-content' }}>
              {quantityDiscountInEffect ? (
                <Fragment>
                  <div>
                    {discountedAmount !== 0 ? (
                      <BodyTextAlt md="0" color="#d4212d">
                        Price with coupon
                      </BodyTextAlt>
                    ) : null}
                    <div className="product-price">
                      <span class="sr-only">Current price:</span>
                      <span className="dollar-sign">$</span>
                      <span className="price">
                        {subscribing
                          ? `${(
                              ((activeQuantityDiscount.price_per_unit * 9) / 10) * quantity -
                              discountedAmount
                            ).toFixed(2)}`
                          : `${(
                              activeQuantityDiscount.price_per_unit * quantity -
                              discountedAmount
                            ).toFixed(2)}`}
                      </span>
                    </div>
                  </div>
                  <div className="savings">
                    <BodyTextAlt md="0 1rem 1px 0">
                      <span class="sr-only">Original price:</span>
                      <strike>
                        $
                        {subscribing
                          ? (((Number(selectedVariant.price.amount) * 10) / 9) * quantity).toFixed(
                              2
                            )
                          : (Number(selectedVariant.price.amount) * quantity).toFixed(2)}
                      </strike>
                    </BodyTextAlt>
                    <BodyTextAlt
                      md="0 1rem 1px 0"
                      color="#d4212c"
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Save $
                      {subscribing
                        ? (
                            ((Number(selectedVariant.price.amount) * 10) / 9 -
                              activeQuantityDiscount.price_per_unit +
                              activeQuantityDiscount.price_per_unit / 10) *
                            quantity
                          ).toFixed(2)
                        : (
                            (Number(selectedVariant.price.amount) -
                              activeQuantityDiscount.price_per_unit) *
                            quantity
                          ).toFixed(2)}
                    </BodyTextAlt>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div>
                    {discountedAmount !== 0 && hasDiscount ? (
                      <BodyTextAlt color="#d4212d" md="0">
                        Price with coupon
                      </BodyTextAlt>
                    ) : null}
                    <div className="product-price">
                      <span class="sr-only">Current price:</span>
                      <span className="dollar-sign">$</span>
                      <span className="price">
                        {(
                          Number(selectedVariant.price.amount) * quantity -
                          (hasDiscount ? discountedAmount : 0)
                        ).toFixed(2)}
                      </span>
                    </div>
                  </div>
                  {subscribing ? (
                    <div className="savings">
                      <BodyTextAlt md="0 1rem 1px 0">
                        <span class="sr-only">Original price: </span>
                        <strike>{`$${(
                          ((Number(selectedVariant.price.amount) * 10) / 9) *
                          quantity
                        ).toFixed(2)}`}</strike>
                      </BodyTextAlt>
                      <BodyTextAlt
                        md="0 1rem 1px 0"
                        color="#d4212c"
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Save $
                        {(
                          ((Number(selectedVariant.price.amount) * 10) / 9 -
                            Number(selectedVariant.price.amount)) *
                          quantity
                        ).toFixed(2)}
                      </BodyTextAlt>
                    </div>
                  ) : null}
                </Fragment>
              )}
            </FlexBox>
            <FlexBox justify="space-between" align="center">
              <BodyTextAlt className="unit-price">
                PRICE PER ITEM: $
                {quantityDiscountInEffect
                  ? subscribing
                    ? ((activeQuantityDiscount.price_per_unit * 9) / 10).toFixed(2)
                    : activeQuantityDiscount.price_per_unit.toFixed(2)
                  : subscribing
                  ? Number(selectedVariant.price.amount).toFixed(2)
                  : Number(selectedVariant.price.amount).toFixed(2)}
              </BodyTextAlt>

              {reviewProductId && (
                <AnchorLink
                  href="#product-reviews"
                  class="yotpo mobile bottomLine"
                  data-product-id={reviewProductId}
                />
              )}
            </FlexBox>
            {discountedAmount !== 0 && hasDiscount ? (
              <DiscountCode>
                <TagIcon
                  style={{
                    width: '17px',
                    marginRight: '5px',
                    fill: '#555',
                  }}
                />
                <BodyTextAlt color="#d4212d" md="0">
                  {discountApplications?.length ? discountApplications[0].code : linkDiscountCode}:
                  -$
                  {discountedAmount.toFixed(2)}
                </BodyTextAlt>
              </DiscountCode>
            ) : null}

            {reviewProductId && (
              <AnchorLink
                href="#product-reviews"
                class="yotpo desktop bottomLine"
                data-product-id={reviewProductId}
              />
            )}
          </div>
          <div
            className="product-desc"
            md="0"
            dangerouslySetInnerHTML={{
              __html: product.descriptionHtml,
            }}
          />
        </FlexBox>
        <div className="product-form">
          <ProductForm
            product={product}
            quantity={quantity}
            setQuantity={setQuantity}
            addToCart={addToCart}
            metafields={metafields}
            isSubscription={isSubscription}
            subscribing={subscribing}
            setSubscribing={setSubscribing}
            setSubscriptionModalIsOpen={setSubscriptionModalIsOpen}
            subscriptionInterval={subscriptionInterval}
            setSubscriptionInterval={setSubscriptionInterval}
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariant}
            selectedRegularVariant={selectedRegularVariant}
            setSelectedRegularVariant={setSelectedRegularVariant}
            upsellProducts={upsellProducts}
            setUpsellModalIsOpen={setUpsellModalIsOpen}
            availableForSale={availableForSale}
            loadingAvailability={loadingAvailability}
            varAvailableQuantity={varAvailableQuantity}
            isAGiftCard={isAGiftCard}
            giftCardErrors={giftCardErrors}
            giftCardName={giftCardName}
            setGiftCardName={setGiftCardName}
            giftCardSenderName={giftCardSenderName}
            setGiftCardSenderName={setGiftCardSenderName}
            giftCardMessage={giftCardMessage}
            setGiftCardMessage={setGiftCardMessage}
            giftCardRecipient={giftCardRecipient}
            setGiftCardRecipient={setGiftCardRecipient}
            confirmGiftCardRecipient={confirmGiftCardRecipient}
            setConfirmGiftCardRecipient={setConfirmGiftCardRecipient}
            giftCardSender={giftCardSender}
            setGiftCardSender={setGiftCardSender}
            confirmGiftCardSender={confirmGiftCardSender}
            setConfirmGiftCardSender={setConfirmGiftCardSender}
            giftCardNotificationDate={giftCardNotificationDate}
            setGiftCardNotificationDate={setGiftCardNotificationDate}
          />
        </div>
        <UpsellModal
          upsellProducts={upsellProducts}
          setUpsellProducts={setUpsellProducts}
          upsellModalIsOpen={upsellModalIsOpen}
          subscribing={subscribing}
          subscriptionInterval={subscriptionInterval}
          setUpsellModalIsOpen={setUpsellModalIsOpen}
          product={product}
          subProduct={subscriptionProduct}
          selectedVariant={selectedVariant}
        />
        <SubscriptionModal
          setSubscribing={setSubscribing}
          subscriptionInterval={subscriptionInterval}
          setSubscriptionInterval={setSubscriptionInterval}
          subscriptionModalIsOpen={subscriptionModalIsOpen}
          setSubscriptionModalIsOpen={setSubscriptionModalIsOpen}
        />
        <BackgroundDiv
          css={css`
            top: 21vw;
            right: 5%;
            width: 900px;
            max-width: 100%;
            z-index: -3;
          `}
        >
          <Image fluid={getImage(backgroundImages.edges, 'blurred_smudge_3@2x')} alt="" />
        </BackgroundDiv>
      </SectionWrapper>
      <StickyATC
        product={product}
        quantity={quantity}
        setQuantity={setQuantity}
        addToCart={addToCart}
        metafields={metafields}
        isSubscription={isSubscription}
        subscribing={subscribing}
        setSubscribing={setSubscribing}
        setSubscriptionModalIsOpen={setSubscriptionModalIsOpen}
        subscriptionInterval={subscriptionInterval}
        setSubscriptionInterval={setSubscriptionInterval}
        selectedVariant={selectedVariant}
        setSelectedVariant={setSelectedVariant}
        selectedRegularVariant={selectedRegularVariant}
        setSelectedRegularVariant={setSelectedRegularVariant}
        upsellProducts={upsellProducts}
        setUpsellModalIsOpen={setUpsellModalIsOpen}
        activeQuantityDiscount={activeQuantityDiscount}
        quantityDiscountInEffect={quantityDiscountInEffect}
        availableForSale={availableForSale}
        loadingAvailability={loadingAvailability}
        isAGiftCard={isAGiftCard}
        varAvailableQuantity={varAvailableQuantity}
      />
    </Fragment>
  );
};

export default ProductDetails;
