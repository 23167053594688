import React from 'react';
import Modal from '~/components/.base/modal';
import { InputSelect } from '~/components/.base/inputs';
import { Heading, BodyText } from '~/components/.base/headings';
import { RedButton, WhiteButton } from '~/components/.base/buttons';
import { Grid, FlexBox } from '~/components/.base/containers';
import ArrowDown from '~/images/icons/arrow_normal_down.svg';
import './SubscriptionModal.scss';

const SubscriptionModal = ({
    setSubscribing,
    subscriptionInterval,
    setSubscriptionInterval,
    subscriptionModalIsOpen,
    setSubscriptionModalIsOpen,
}) => {
    return (
        <Modal
            open={subscriptionModalIsOpen}
            onClose={() => {
                setSubscriptionModalIsOpen(false);
            }}
            ariaLabelledby="subscription-modal-title"
            top="0"
            modalMaxWidth="650px"
        >
            <Grid className="subscription-modal-body">
                <FlexBox className="modal-upper" align="flex-end">
                    <Heading id="subscription-modal-title" md="0">
                        Subscriptions: How They Work
                    </Heading>
                </FlexBox>
                <div className={`subscription-body`}>
                    <BodyText>
                        Below, please choose your subscription interval to sign up for recurring
                        automated orders and deliveries, and get 10% off your order!
                    </BodyText>
                    <BodyText>
                        Before each recurring order, we will email you 7 days ahead of time to make
                        you aware of your upcoming order as well as to remind you to adjust your
                        delivery date should you need to. Orders that do not get adjusted will ship
                        on the nearest delivery date available for your chosen interval of 30/60/90
                        days.
                    </BodyText>
                    <BodyText>
                        You will receive our standard email confirmation once your order generates 4
                        days before your order ships, and the usual tracking information once your
                        order ships.
                    </BodyText>
                    <BodyText>
                        For your flexibility, you can manage your subscription settings at any time
                        by logging into your account using the link at the top of the page in our
                        online shop.
                    </BodyText>
                    <BodyText>Please select your subscription interval below:</BodyText>
                    <div className="interval-cont">
                        <InputSelect w="100%">
                            <select
                                style={{
                                    height: 60,
                                    borderRadius: 30,
                                }}
                                value={subscriptionInterval}
                                onChange={e => setSubscriptionInterval(e.target.value)}
                                onBlur={e => setSubscriptionInterval(e.target.value)}
                            >
                                <option value="">Select an interval</option>
                                <option value="30">SHIPS EVERY 30 DAYS</option>
                                <option value="60">SHIPS EVERY 60 DAYS</option>
                                <option value="90">SHIPS EVERY 90 DAYS</option>
                            </select>
                            <ArrowDown
                                style={{
                                    right: '15px',
                                    top: '23px',
                                    pointerEvents: 'none',
                                }}
                            />
                        </InputSelect>
                    </div>
                </div>
                <FlexBox justify="flex-end" className="modal-footer">
                    <WhiteButton
                        onClick={() => {
                            setSubscriptionModalIsOpen(false);
                        }}
                    >
                        Cancel
                    </WhiteButton>
                    <RedButton
                        disabled={subscriptionInterval === ''}
                        onClick={() => {
                            setSubscriptionModalIsOpen(false);
                            setSubscribing(true);
                        }}
                        md="0 0 0 1rem"
                    >
                        Continue
                    </RedButton>
                </FlexBox>
            </Grid>
        </Modal>
    );
};

export default SubscriptionModal;
