import React, { useState, useEffect } from 'react';
import Image from 'gatsby-image';
import styled, { css } from 'styled-components';

import { breakpoint } from '../../../constants/Breakpoint';
import { getImage, getShopifyImageTransformedSrc } from '~/helpers';
import { HeadingLarge } from '~/components/.base/headings';
import { FlexBox, Row, Col } from '~/components/.base/containers';
import { BackgroundDivBgImage } from '~/components/.base/containers';
import { WoodenBorder } from '../../.base/border';

const SectionWrapper = styled.div`
    position: relative;
    padding-top: 100px;
    min-height: 900px;
    .reviews-header,
    .yotpo.yotpo-main-widget {
        width: 90%;
        max-width: 1000px;
        margin: auto;
        .promoted-products-box {
            display: none !important;
        }
    }
    .reviews-images {
        .gatsby-image-wrapper {
            width: 166px;
            height: 166px;
            margin: 0 4px;
        }
        .image-grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin: -4px 4px 11px;
            .gatsby-image-wrapper {
                width: 50px;
                height: 50px;
                margin: 2px;
            }
        }
    }
    @media ${breakpoint.maxSm} {
        min-height: 0px;
        padding-bottom: 80px;
        .reviews-header {
            flex-direction: column;
            h2 {
                margin-bottom: 1rem;
            }
        }
        .reviews-images {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin: 30px -5px 30px;
            .gatsby-image-wrapper {
                margin: 0 5px;
                width: auto;
                height: auto;
                &.no-mobile {
                    display: none;
                }
            }
            .image-grid {
                grid-column: 1/-1;
                margin: 5px 0 11px;
                .gatsby-image-wrapper {
                    width: auto;
                    height: auto;
                    margin: 5px;
                }
            }
        }
    }
`;

const Reviews = ({ product, productImages, showStickyMenu, reviewsRef, location }) => {
    const [reviewDivLoaded, setReviewDivLoaded] = useState(false);
    const [reviewProductID, setReviewProductID] = useState('');

    useEffect(() => {
        if (document && reviewDivLoaded) {
            let docu = document.querySelectorAll('.yotposcript');
            if (!docu.length > 0) {
                var e = document.createElement('script');
                e.setAttribute('class', 'yotposcript');

                e.type = 'text/javascript';
                e.async = true;
                e.src = '//staticw2.yotpo.com/qmhbx5xc81CpAqVQgnxtUJI6REiLJac6QIV7Z8CJ/widget.js';
                var t = document.getElementsByTagName('script')[0];
                t.parentNode.insertBefore(e, t);
            }
        }
        if (window && window.yotpo) {
            setTimeout(() => window.yotpo.refreshWidgets(), 2000);
        }
    }, [reviewDivLoaded]);

    useEffect(() => {
        if (document) {
            setReviewDivLoaded(true);
            var newId = product.id.replace('Shopify__Product__', '').replace(
                'gid://shopify/Product/',
                ''
            );
            setReviewProductID(newId);
        }
    }, [location, product.id]);

    return (
        <SectionWrapper id="product-reviews" tabIndex="-1">
            <div style={{ position: 'relative', top: '-10vh' }} />
            <Row className="product-reviews" ref={reviewsRef}>
                <BackgroundDivBgImage
                    w="100%"
                    h="100vh"
                    heightMobile="100vh"
                    top="0"
                    topMobile="0"
                    color="#edf2f2"
                    style={{ zIndex: '-2' }}
                />
                <WoodenBorder top="0" />
                <WoodenBorder top="100vh" />
                {showStickyMenu ? <Col className="sticky-menu-col" size={1}></Col> : null}
                <Col size={4}>
                    <FlexBox className="reviews-header" justify="space-between">
                        <HeadingLarge
                            as="h2"
                            className="reviews-title"
                            css={css`
                                br {
                                    @media ${breakpoint.maxSm} {
                                        display: none;
                                    }
                                }
                            `}
                        >
                            What
                            <br /> others
                            <br /> are
                            <br /> <span className="red">saying</span>
                        </HeadingLarge>
                        <FlexBox className="reviews-images">
                            <Image fluid={getImage(productImages, 'reviews-1')} alt="reviews 1" />
                            <Image fluid={getImage(productImages, 'reviews-2')} alt="reviews 2" />
                            <div className="image-grid">
                                <Image
                                    fluid={getImage(productImages, 'reviews-3')}
                                    alt="reviews 1"
                                />
                                <Image
                                    fluid={getImage(productImages, 'reviews-4')}
                                    alt="reviews 2"
                                />

                                <Image
                                    fluid={getImage(productImages, 'reviews-5')}
                                    alt="reviews 1"
                                />
                                <Image
                                    fluid={getImage(productImages, 'reviews-6')}
                                    alt="reviews 2"
                                />

                                <Image
                                    fluid={getImage(productImages, 'reviews-7')}
                                    alt="reviews 1"
                                />
                                <Image
                                    fluid={getImage(productImages, 'reviews-8')}
                                    alt="reviews 2"
                                />

                                <Image
                                    className="no-mobile"
                                    fluid={getImage(productImages, 'reviews-9')}
                                    alt="reviews 1"
                                />
                                <Image
                                    className="no-mobile"
                                    fluid={getImage(productImages, 'reviews-2')}
                                    alt="reviews 2"
                                />

                                <Image
                                    className="no-mobile"
                                    fluid={getImage(productImages, 'reviews-1')}
                                    alt="reviews 1"
                                />
                            </div>
                        </FlexBox>
                    </FlexBox>
                    {reviewDivLoaded && product.images && product.images[0] ? (
                        <div
                            className="yotpo yotpo-main-widget"
                            data-product-id={reviewProductID}
                            data-price={product.variants[0].price.amount}
                            data-currency={product.priceRange.minVariantPrice.currencyCode}
                            data-name={product.title}
                            data-url={`/products/${product.handle}`}
                            data-image-url={getShopifyImageTransformedSrc(
                                product.images[0].originalSrc,
                                '_300x'
                            )}
                        ></div>
                    ) : null}
                </Col>
            </Row>
        </SectionWrapper>
    );
};

export default Reviews;
