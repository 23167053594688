import { useState } from 'react';

export const useToggleModalOpen = ({ open }) => {
    const [isModalOpen, setIsModalOpen] = useState(open);

    const handleIsModalOpen = bool => {
        setIsModalOpen(bool);
    };

    return { isModalOpen, handleIsModalOpen };
};
