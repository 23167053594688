import React, { useState, useEffect } from 'react';
import Image from 'gatsby-image';
import styled from 'styled-components';

import { breakpoint } from '../../../constants/Breakpoint';
import Slideshow from '~/components/.base/slideshow';

const ProductGalleryWrapper = styled.div`
    position: relative;
    padding: 0 40px 0 0;
    z-index: 1;
    .image-thumbs {
        position: absolute;
        top: 15px;
        left: 35px;
        z-index: 1;
        display: none;
        .thumb {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            cursor: pointer;
            margin-bottom: 8px;
            overflow: hidden;
            border: 1px solid #e2e2e4;
            display: flex;
            flex-direction: column;
            justify-content: center;
            outline: none;
            img {
                width: 100%;
                border-radius: 22px;
            }
        }
    }
    .image-main {
        border-radius: 30px;
        border: solid 1px #e1e4e4;
        margin-bottom: 1rem;
        overflow: hidden;
        .image {
            width: 100%;
        }
    }
    @media ${breakpoint.maxMd} {
        padding: 0px;
        .image-main {
            border-width: 0 0 1px;
            border-bottom: 1px solid #e2e2e4;
            // padding: 20px 50px;
            border-radius: 0px;
            margin-bottom: 30px;
            /* width: 100vw;
			height: 100vw; */
        }
        .slideshow {
            .carousel {
                overflow: visible;
            }
            .control-dots,
            .prev-next {
                bottom: 20px;
            }
            .control-dots {
                width: fit-content;
                .circle .inner {
                    background-color: rgba(92, 101, 102, 0.7);
                }
            }
            .image-main {
                margin-bottom: 60px;
            }
        }
    }
    @media ${breakpoint.lg} {
        .slider {
            /* Disable slidshow layout using css */
            flex-wrap: wrap;
            transform: none !important;
        }
        .prev-next {
            display: none;
        }
        .control-dots {
            display: none;
        }
    }
`;

const ProductGallery = ({
    cartRef,
    t1Ref,
    product,
    metafields,
    title,
    subtitle,
    subscribing,
    setSubscribing,
}) => {
    const [selectedImg, setSelectedImg] = useState({});
    useEffect(() => {
        if (product.images.length) {
            updateSelectedImg(product.images[0]);
        }
    }, [product]);
    const updateSelectedImg = image => {
        setSelectedImg(image);
    };
    return (
        <ProductGalleryWrapper className="product-gallery">
            <div className="image-thumbs">
                {product?.images?.map(
                    (image, i) =>
                        image?.localFile?.childImageSharp?.fluid && (
                            <button className="thumb" onClick={() => setSelectedImg(image)} key={i}>
                                <Image fluid={image.localFile.childImageSharp.fluid} alt="" />
                            </button>
                        )
                )}
            </div>
            {product.images.length > 1 ? (
                <Slideshow carouselLabel="product gallery" noLoop={true}>
                    {product.images.map(
                        (image, i) =>
                            image?.localFile?.childImageSharp?.fluid && (
                                <div key={i} id={`slide-${i}`} className="image-main">
                                    <Image fluid={image.localFile.childImageSharp.fluid} alt="" />
                                </div>
                            )
                    )}
                </Slideshow>
            ) : (
                selectedImg?.localFile?.childImageSharp?.fluid && (
                    <div className="image-main">
                        <Image fluid={selectedImg.localFile.childImageSharp.fluid} alt="" />
                    </div>
                )
            )}
        </ProductGalleryWrapper>
    );
};
export default ProductGallery;
