import React from 'react';
import { BodyTextAlt } from '~/components/.base/headings';
import './StickyMenu.scss';
const StickyMenu = ({ shippingInView, originsInView, cookingInView, reviewsInView }) => {
  const handleMenuItemClick = e => {
    e.preventDefault();
    const scrollToElm = document.querySelector(e.currentTarget.getAttribute('href'));
    scrollToElm.scrollIntoView({
      behavior: 'smooth',
    });
    scrollToElm.focus({ preventScroll: true });
  };
  return (
    <nav id="sticky-menu" className="product-sticky-menu" aria-label="in-product">
      <ul>
        <li
          className={
            shippingInView && !originsInView && !cookingInView ? 'menu-item active' : 'menu-item'
          }
        >
          <a href="#shipping-info" onClick={handleMenuItemClick}>
            <BodyTextAlt>Shipping</BodyTextAlt>
          </a>
        </li>
        <li
          className={
            originsInView && !cookingInView && !reviewsInView ? 'menu-item active' : 'menu-item'
          }
        >
          <a href="#product-origins-main" onClick={handleMenuItemClick}>
            <BodyTextAlt>Origins</BodyTextAlt>
          </a>
        </li>
        <li className={cookingInView ? 'menu-item active' : 'menu-item'}>
          <a href="#cooking-instructions" onClick={handleMenuItemClick}>
            <BodyTextAlt>How to cook</BodyTextAlt>
          </a>
        </li>
        <li
          className={
            !shippingInView && !originsInView && !cookingInView && reviewsInView
              ? 'menu-item active last'
              : 'menu-item last'
          }
        >
          <a href="#product-reviews" onClick={handleMenuItemClick}>
            <BodyTextAlt>Reviews</BodyTextAlt>
          </a>
        </li>
      </ul>
      <div className="menu-item filler" />
    </nav>
  );
};

export default StickyMenu;
