import React, { useState, useEffect } from 'react';
import { graphql, useScrollRestoration } from 'gatsby';
import Image from 'gatsby-image';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';

import { breakpoint } from '../../constants/Breakpoint';
import { getImage, getMetafield } from '~/helpers';
import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';
import {
    ProductDetails,
    ProductGallery,
    ShippingInfo,
    ProductOrigins,
    CookingInstructions,
    StickyMenu,
    Reviews,
    RecommendedProducts,
} from '~/components/ProductPage';
import {
    Container,
    FlexBox,
    PageContainerEcomm,
    BackgroundDiv,
} from '~/components/.base/containers';
import MailingListPopup from '~/components/JoinMailingList/MailingListPopup';
import { getProductJSONLD } from '../../helpers/microdataSchema/product';

const Pdp1 = styled(Container)`
    padding-top: 60px;
    padding-bottom: 60px;
    .product-gallery,
    .product-details {
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media ${breakpoint.maxMd} {
        padding: 0px;
        .product-gallery,
        .product-details {
            width: 100%;
            flex: 0 0 100%;
            max-width: 623px;
        }
    }
`;

const Pdp2 = styled.div`
    padding-top: 2rem;
    position: relative;
    .sticky-menu-col {
        width: 230px;
        @media ${breakpoint.maxLg} {
            display: none;
        }
    }
`;

const Pdp3 = styled.div`
    position: relative;
    overflow: hidden;
    .sticky-menu-col {
        width: 230px;
        @media ${breakpoint.maxLg} {
            display: none;
        }
    }
`;

const ProductPage = props => {
    const {
        product,
        sub_product,
        product: { metafields, collections },
        productImages,
        backgroundImages,
    } = props.data;

    const { location } = { ...props };
    const [showStickyMenu, setShowStickyMenu] = useState(true);
    const [showShippingInfo, setShowShippingInfo] = useState(true);
    const [showProductOrigins, setShowProductOrigins] = useState(true);
    const [showCookingInstructions, setShowCookingInstructions] = useState(true);
    const [showReviews, setShowReviews] = useState(true);
    const [criteoProductId, setCriteoProductId] = useState(null);
    const { ref: shippingRef, inView: shippingInView } = useInView({
        /* Optional options */
        threshold: 0.7,
    });
    const { ref: originsRef, inView: originsInView } = useInView({
        /* Optional options */
        threshold: 0.7,
    });
    const { ref: cookingRef, inView: cookingInView } = useInView({
        /* Optional options */
        threshold: 0.7,
    });
    const { ref: reviewsRef, inView: reviewsInView } = useInView({
        /* Optional options */
        threshold: 0,
    });
    useEffect(() => {
        const hideShipping = getMetafield(metafields, 'hide_shipping_info');
        const hideProductOrigins = getMetafield(metafields, 'hide_product_origins');
        const hideCookingInstructions = getMetafield(metafields, 'hide_cooking_section');
        const hideReviews = getMetafield(metafields, 'hide_reviews');
        setShowShippingInfo(!hideShipping);
        setShowProductOrigins(!hideProductOrigins);
        setShowReviews(!hideReviews);
        setShowCookingInstructions(!hideCookingInstructions);
        let count = 0;
        if (hideShipping) count++;
        if (hideProductOrigins) count++;
        if (hideCookingInstructions) count++;
        if (hideReviews) count++;
        if (count > 1) setShowStickyMenu(false);
    }, [props, metafields]);
    useEffect(() => {
        if (window && document) {
            if (window.ga) {
                window.ga('require', 'ec');
                window.ga('ec:addProduct', {
                    name: product.title,
                    id: product.shopifyId,
                    price: product.variants[0].price.amount,
                    brand: 'CML',
                    variant: product.variants[0].title,
                    category:
                        collections.edges && collections.edges.length
                            ? collections.edges[0].node.title
                            : 'Other',
                });
                window.ga('ec:setAction', 'detail');
                window.ga('send', 'event', 'UX', 'view', 'product page');
            }
        }
    }, []);
    useEffect(() => {
        if (window && document) {
            const checkMenuScroll = function () {
                const menuItems = document.querySelectorAll('#sticky-menu .menu-item');
                const originSection = document.getElementById('product-origins-main');
                if (menuItems && originSection) {
                    const originSectionRect = originSection.getBoundingClientRect();
                    [...menuItems].forEach(item => {
                        if (
                            item.getBoundingClientRect().top > originSectionRect.top + 20 &&
                            item.getBoundingClientRect().top < originSectionRect.bottom - 180
                        ) {
                            item.classList.add('in-view');
                        } else {
                            item.classList.remove('in-view');
                        }
                    });
                }
            };
            window.addEventListener('scroll', checkMenuScroll, {
                passive: true,
            });
            return function () {
                window.removeEventListener('scroll', checkMenuScroll, {
                    passive: true,
                });
            };
        }
    }, []);
    useEffect(() => {
        if (window) {
            setCriteoProductId(
                `shopify_us_${product.shopifyId.replace('gid://shopify/Product/', '')}_${
                    product.variants[0].shopifyId
                .replace('gid://shopify/ProductVariant/', '')}`
            );
        }
    }, [product]);
    const [toggle, setToggle] = useState(0);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.attentive) {
                console.log('attentive', {
                    items: [
                        {
                            productId: product.shopifyId,
                            productVariantId: product.variants[0].shopifyId,
                            name: product.title,
                            productImage: product.images[0].originalSrc,
                            category: 'CML',
                            price: {
                                value: product.variants[0].price.amount,
                                currency: 'USD',
                            },
                        },
                    ],
                });
                window.attentive.analytics.productView({
                    items: [
                        {
                            productId: product.shopifyId,
                            productVariantId: product.variants[0].shopifyId,
                            name: product.title,
                            productImage: product.images[0].originalSrc,
                            category: 'CML',
                            price: {
                                value: product.variants[0].price.amount,
                                currency: 'USD',
                            },
                        },
                    ],
                });
            } else {
                if (toggle < 10) {
                    console.log('no attentive');
                    setTimeout(() => setToggle(toggle + 1), 1000);
                }
            }
        }
    }, [toggle]);
    useEffect(() => {
        if (
            window &&
            (product.handle === 'wet-napkins' ||
                product.handle === 'packet-of-sea-salt' ||
                product.handle === 'bibs' ||
                product.handle === 'crackers' ||
                product.handle === 'placemats')
        ) {
            window.location.href = '/products/cml-lobster-toolkit/';
        } else if (
            window &&
            (product.handle === 'stick-of-butter' || product.handle === '6-pack-new-england-rolls')
        ) {
            window.location.href = '/products/the-at-home-lobster-roll-kit/';
        } else if (window && product.handle === 'red-velvet-whoopie-pie-2-pack') {
            window.location.href = '/products/chocolate-whoopie-pie-4-pack/';
        } else if (
            window &&
            (product.handle === 'lemons' || product.handle === 'example-apparel-item')
        ) {
            window.location.href = '/collections/other/';
        }
    }, [product]);
    let metaData;
    if (product.images[0]) {
        metaData = [
            {
                property: `og:image`,
                content: product.images[0].originalSrc,
            },
        ];
    }
    console.log(location);
    const pdp1ScrollRestoration = useScrollRestoration(`page-component-pdp1`);
    return (
        <PageContainerEcomm className="product-page">
            <MailingListPopup />
            <SEO
                title={product?.seo?.title ? product.seo.title : product.title}
                description={
                    product?.seo?.description ? product.seo.description : product.description
                }
                meta={metaData}
                jsonLD={getProductJSONLD(product)}
            />
            <Criteo type="product" criteoProductId={criteoProductId} />
            <DeriveHeaderClasses location={location} />
            <Pdp1 {...pdp1ScrollRestoration}>
                <FlexBox
                    justify="center"
                    css={css`
                        flex-wrap: wrap;
                    `}
                >
                    <ProductGallery product={product} />
                    <ProductDetails
                        locationSearch={location.search}
                        product={product}
                        subscriptionProduct={sub_product}
                        metafields={metafields}
                        collections={collections}
                        backgroundImages={backgroundImages}
                        sourceCollection={props.sourceCollection}
                    />
                </FlexBox>
            </Pdp1>
            <Pdp2>
                {showStickyMenu ? (
                    <StickyMenu
                        shippingInView={shippingInView}
                        originsInView={originsInView}
                        cookingInView={cookingInView}
                        reviewsInView={reviewsInView}
                    />
                ) : null}
                <div id="cart-anchor" />
                {showShippingInfo ? (
                    <ShippingInfo
                        productImages={productImages.edges}
                        showStickyMenu={showStickyMenu}
                        shippingRef={shippingRef}
                    />
                ) : null}
                {showProductOrigins ? (
                    <ProductOrigins
                        metafields={metafields}
                        productImages={productImages.edges}
                        originsRef={originsRef}
                    />
                ) : null}
                {showCookingInstructions ? (
                    <CookingInstructions
                        productImages={productImages.edges}
                        showStickyMenu={showStickyMenu}
                        cookingRef={cookingRef}
                        videoUrl={getMetafield(metafields, 'cooking_section_video')}
                    />
                ) : null}
            </Pdp2>
            <Pdp3>
                <BackgroundDiv
                    css={css`
                        bottom: -22vw;
                        width: 52vw;
                        max-width: 727px;
                        left: -21vw;
                        z-index: -5;
                    `}
                >
                    <Image
                        fluid={getImage(backgroundImages.edges, 'blurred_smudge_4@2x')}
                        alt="texture"
                    />
                </BackgroundDiv>
                {showReviews ? (
                    <Reviews
                        product={product}
                        productImages={productImages.edges}
                        showStickyMenu={showStickyMenu}
                        reviewsRef={reviewsRef}
                    />
                ) : null}
                <RecommendedProducts
                    metafields={metafields}
                    product={product}
                    collection={
                        collections && collections.edges.length ? collections.edges[0].node : null
                    }
                />
            </Pdp3>
        </PageContainerEcomm>
    );
};

export const query = graphql`
    query ($handle: String!, $sub_handle: String!) {
        sub_product: shopifyProduct(handle: { eq: $sub_handle }) {
            variants {
                title
                shopifyId
                price {
                  amount
                  currencyCode
                }
                availableForSale
            }
        }
        product: shopifyProduct(handle: { eq: $handle }) {
            id
            title
            handle
            productType
            description
            descriptionHtml
            shopifyId
            collections {
                edges {
                    node {
                        handle
                        title
                        products {
                            edges {
                                node {
                                    handle
                                    images {
                                        edges {
                                            node {
                                                originalSrc
                                            }
                                        }
                                    }
                                    title
                                    variants {
                                        edges {
                                            node {
                                                price {
                                                  amount
                                                  currencyCode
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            seo {
                title
                description
            }
            options {
                id
                name
                values
            }
            variants {
                id
                title
                price {
                  amount
                  currencyCode
                }
                availableForSale
                shopifyId
                sku
                selectedOptions {
                    name
                    value
                }
            }
            priceRange {
                minVariantPrice {
                    amount
                    currencyCode
                }
                maxVariantPrice {
                    amount
                    currencyCode
                }
            }
            metafields {
                key
                value
            }
            images {
                originalSrc
                id
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        productImages: allFile(
            filter: {
                extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                absolutePath: { regex: "/images/pdp/" }
            }
        ) {
            edges {
                node {
                    name
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            aspectRatio
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        backgroundImages: allFile(
            filter: {
                extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                absolutePath: { regex: "/images/background/" }
            }
        ) {
            edges {
                node {
                    name
                    childImageSharp {
                        fluid(maxWidth: 1000) {
                            aspectRatio
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;

export default ProductPage;
