import React, { useState, useEffect, Fragment } from 'react';
import { useQueryParam, NumberParam } from 'use-query-params';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { RedButton, WhiteButton, WhiteSubscriptionButton } from '~/components/.base/buttons';
import { FlexBox, Row, Col } from '~/components/.base/containers';
import { InputSelect, FormControl } from '~/components/.base/inputs';
import ArrowDown from '~/images/icons/arrow_normal_down.svg';
import PlusIcon from '~/images/icons/plus.svg';
import MinusIcon from '~/images/icons/minus.svg';
import SubscriptionIcon from '~/images/icons/pdp/subscription.svg';
import InfoIcon from '~/images/icons/pdp/info.svg';
import CloseIcon from '~/images/icons/pdp/close.svg';
import { BeforeAddGiftCardToCartModal } from '~/components/ProductPage';
import { useToggleModalOpen } from 'hooks';

const ProductForm = ({
  product,
  quantity,
  setQuantity,
  addToCart,
  metafields,
  isSubscription,
  subscribing,
  setSubscribing,
  setSubscriptionModalIsOpen,
  subscriptionInterval,
  setSubscriptionInterval,
  selectedVariant,
  setSelectedVariant,
  selectedRegularVariant,
  setSelectedRegularVariant,
  setUpsellModalIsOpen,
  upsellProducts,
  activeQuantityDiscount,
  quantityDiscountInEffect,
  availableForSale,
  loadingAvailability,
  varAvailableQuantity,
  isAGiftCard,
  giftCardErrors,
  giftCardName,
  setGiftCardName,
  giftCardSenderName,
  setGiftCardSenderName,
  giftCardMessage,
  setGiftCardMessage,
  giftCardRecipient,
  setGiftCardRecipient,
  confirmGiftCardRecipient,
  setConfirmGiftCardRecipient,
  giftCardSender,
  setGiftCardSender,
  confirmGiftCardSender,
  setConfirmGiftCardSender,
  giftCardNotificationDate,
  setGiftCardNotificationDate,
}) => {
  const [variant, setVariant] = useQueryParam('variant', NumberParam);

  const handleVariantChange = e => {
    const selVar = product.variants.find(p => p.shopifyId === e.target.value);
    setSelectedVariant(selVar);
    setSelectedRegularVariant(selVar);
    setVariant(`${e.target.value.replace('gid://shopify/ProductVariant/', '')}`);
  };

  const [minGiftCardNotificationDate, setMinGiftCardNotificationDate] = useState(null);

  useEffect(() => {
    const d = new Date();
    setGiftCardNotificationDate(d);
    setMinGiftCardNotificationDate(d);
  }, []);
  const beforeAddGiftCardToCartModal = useToggleModalOpen({ open: false });
  return (
    <>
      {isSubscription ? (
        product.options[0].name !== 'Title' ? (
          // subscription AND variants (i.e. live lobsters)
          <Fragment>
            <Row className="product-details-row" align="flex-end">
              <Col size={4} pd="0 5px 10px 0">
                <VariantSelect
                  product={product}
                  selectedRegularVariant={selectedRegularVariant}
                  handleVariantChange={handleVariantChange}
                />
              </Col>
              <Col size={3} className="quantity-cont" pd="0 0 10px 0">
                <QuantityCont
                  product={product}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  varAvailableQuantity={varAvailableQuantity}
                />
              </Col>
            </Row>
            <Row className="product-details-row" align="flex-end">
              <Col size={4} pd="0 5px 10px 0">
                <ATCButton
                  isAGiftCard={isAGiftCard}
                  handleIsBeforeAddGiftCardToCartModalOpen={
                    beforeAddGiftCardToCartModal.handleIsModalOpen
                  }
                  subscribing={subscribing}
                  addToCart={addToCart}
                  availableForSale={availableForSale}
                  loadingAvailability={loadingAvailability}
                />
              </Col>
            </Row>
          </Fragment>
        ) : (
          // subscription, no variants (i.e. crab meat)
          <Fragment>
            <Row className="product-details-row" align="flex-end">
              <Col size={3} className="quantity-cont" pd="0 5px 10px 0">
                <QuantityCont
                  product={product}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  varAvailableQuantity={varAvailableQuantity}
                />
              </Col>
              <Col size={4} pd="0 0 10px 0" className="order-2-mobile">
                <ATCButton
                  isAGiftCard={isAGiftCard}
                  handleIsBeforeAddGiftCardToCartModalOpen={
                    beforeAddGiftCardToCartModal.handleIsModalOpen
                  }
                  subscribing={subscribing}
                  addToCart={addToCart}
                  availableForSale={availableForSale}
                  loadingAvailability={loadingAvailability}
                />
              </Col>
            </Row>
          </Fragment>
        )
      ) : product.options[0].name !== 'Title' ? (
        // no subscription, variants (i.e. gift cards)
        <Fragment>
          <Row className="product-details-row">
            <Col size={1} pd="0 5px 10px 0">
              <VariantSelect
                product={product}
                selectedRegularVariant={selectedRegularVariant}
                handleVariantChange={handleVariantChange}
              />
            </Col>
            {!isAGiftCard && (
              <Col size={1} className="quantity-cont" pd="0 0 10px 0">
                <QuantityCont
                  product={product}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  varAvailableQuantity={varAvailableQuantity}
                />
              </Col>
            )}
          </Row>
          {isAGiftCard ? (
            <Fragment>
              <Row className="product-details-row">
                <Col size={1} pd="0 0 10px 0">
                  <FormControl mb="0" className="input" w="100%">
                    <label className="label" htmlFor="recipient_name">
                      Recipient Name
                    </label>
                    <input
                      name="recipient_name"
                      type="text"
                      className={`field full${!!giftCardErrors.giftCardName ? ' is-invalid' : ''}`}
                      onChange={e => setGiftCardName(e.target.value)}
                      value={giftCardName}
                    />
                    <div className="error">{giftCardErrors.giftCardName}</div>
                  </FormControl>
                </Col>
              </Row>
              <Row className="product-details-row">
                <Col size={1} pd="0 0 10px 0">
                  <FormControl mb="0" className="input" w="100%">
                    <label className="label" htmlFor="recipient_email">
                      Recipient Email
                    </label>
                    <input
                      name="recipient_email"
                      type="text"
                      className={`field full${
                        !!giftCardErrors.giftCardRecipient ? ' is-invalid' : ''
                      }`}
                      onChange={e => setGiftCardRecipient(e.target.value)}
                      value={giftCardRecipient}
                    />
                    <div className="error">{giftCardErrors.giftCardRecipient}</div>
                  </FormControl>
                </Col>
              </Row>
              <Row className="product-details-row">
                <Col size={1} pd="0 0 10px 0">
                  <FormControl mb="0" className="input" w="100%">
                    <label className="label" htmlFor="recipient_email">
                      Confirm Recipient Email
                    </label>
                    <input
                      name="recipient_email"
                      type="text"
                      className={`field full${
                        !!giftCardErrors.confirmGiftCardRecipient ? ' is-invalid' : ''
                      }`}
                      onChange={e => setConfirmGiftCardRecipient(e.target.value)}
                      value={confirmGiftCardRecipient}
                    />
                    <div className="error">{giftCardErrors.confirmGiftCardRecipient}</div>
                  </FormControl>
                </Col>
              </Row>
              <Row className="product-details-row">
                <Col size={1} pd="0 0 10px 0">
                  <FormControl mb="0" className="input" w="100%">
                    <label className="label" htmlFor="sender_name">
                      Sender Name
                    </label>
                    <input
                      name="sender_name"
                      type="text"
                      className={`field full${
                        !!giftCardErrors.giftCardSenderName ? ' is-invalid' : ''
                      }`}
                      onChange={e => setGiftCardSenderName(e.target.value)}
                      value={giftCardSenderName}
                    />
                    <div className="error">{giftCardErrors.giftCardSenderName}</div>
                  </FormControl>
                </Col>
              </Row>
              <Row className="product-details-row">
                <Col size={1} pd="0 0 10px 0">
                  <FormControl mb="0" className="input" w="100%">
                    <label className="label" htmlFor="sender_email">
                      Sender Email
                    </label>
                    <input
                      name="sender_email"
                      type="text"
                      className={`field full${
                        !!giftCardErrors.giftCardSender ? ' is-invalid' : ''
                      }`}
                      onChange={e => setGiftCardSender(e.target.value)}
                      value={giftCardSender}
                    />
                    <div className="error">{giftCardErrors.giftCardSender}</div>
                  </FormControl>
                </Col>
              </Row>
              <Row className="product-details-row">
                <Col size={1} pd="0 0 10px 0">
                  <FormControl mb="0" className="input" w="100%">
                    <label className="label" htmlFor="sender_email">
                      Confirm Sender Email
                    </label>
                    <input
                      name="sender_email"
                      type="text"
                      className={`field full${
                        !!giftCardErrors.confirmGiftCardSender ? ' is-invalid' : ''
                      }`}
                      onChange={e => setConfirmGiftCardSender(e.target.value)}
                      value={confirmGiftCardSender}
                    />
                    <div className="error">{giftCardErrors.confirmGiftCardSender}</div>
                  </FormControl>
                </Col>
              </Row>
              <Row className="product-details-row">
                <Col size={1} pd="0 0 10px 0">
                  <FormControl mb="0" className="input" w="100%">
                    <label className="label" htmlFor="message">
                      Gift Message
                    </label>
                    <textarea
                      name="message"
                      type="text"
                      rows={4}
                      className={`field full${
                        !!giftCardErrors.giftCardMessage ? ' is-invalid' : ''
                      }`}
                      onChange={e => setGiftCardMessage(e.target.value)}
                      value={giftCardMessage}
                    />
                    <div className="error">{giftCardErrors.giftCardMessage}</div>
                  </FormControl>
                </Col>
              </Row>
              <Row className="product-details-row">
                <Col size={1} pd="0 0 10px 0">
                  <FormControl mb="0" className="input" w="100%">
                    <label className="label" htmlFor="ReceptionDate">
                      Delivery Date
                    </label>
                    <div className="date-cont">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk
                          disableToolbar
                          variant="static"
                          openTo="date"
                          allowKeyboardControl={false}
                          id="date-picker-inline"
                          value={giftCardNotificationDate}
                          onChange={d => setGiftCardNotificationDate(d)}
                          minDate={minGiftCardNotificationDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="error">{giftCardErrors.giftCardNotificationDate}</div>
                  </FormControl>
                </Col>
              </Row>
            </Fragment>
          ) : null}
          <Row className="product-details-row">
            <ATCButton
              isAGiftCard={isAGiftCard}
              handleIsBeforeAddGiftCardToCartModalOpen={
                beforeAddGiftCardToCartModal.handleIsModalOpen
              }
              subscribing={subscribing}
              addToCart={addToCart}
              availableForSale={availableForSale}
              loadingAvailability={loadingAvailability}
            />
          </Row>
        </Fragment>
      ) : (
        // no subscription, variants (i.e. vacationland)
        <Row className="product-details-row single" align="flex-end">
          <Col size={1} className="quantity-cont" pd="0 5px 0 0">
            <QuantityCont
              product={product}
              quantity={quantity}
              setQuantity={setQuantity}
              varAvailableQuantity={varAvailableQuantity}
            />
          </Col>
          <Col size={1} className="quantity-cont">
            <ATCButton
              isAGiftCard={isAGiftCard}
              handleIsBeforeAddGiftCardToCartModalOpen={
                beforeAddGiftCardToCartModal.handleIsModalOpen
              }
              subscribing={subscribing}
              addToCart={addToCart}
              availableForSale={availableForSale}
              loadingAvailability={loadingAvailability}
            />
          </Col>
        </Row>
      )}
      <BeforeAddGiftCardToCartModal
        isAGiftCard={isAGiftCard}
        beforeAddGiftCardToCartModal={beforeAddGiftCardToCartModal}
        addToCart={addToCart}
      />
    </>
  );
};

const VariantSelect = ({ product, selectedRegularVariant, handleVariantChange }) => {
  return (
    <InputSelect mb="0px" h="60px" svgTop="44px">
      <div className="label">Choose {product.options[0].name}</div>
      <select
        value={selectedRegularVariant.shopifyId}
        onChange={handleVariantChange}
        onBlur={handleVariantChange}
      >
        {product.variants.map((variant, i) => (
          <option key={i} value={variant.shopifyId}>
            {variant.title}
          </option>
        ))}
      </select>
      <ArrowDown style={{ bottom: '22px', pointerEvents: 'none' }} />
    </InputSelect>
  );
};

const QuantityCont = ({ product, quantity, setQuantity, varAvailableQuantity }) => {
  return (
    <Fragment>
      <div className="label">quantity</div>
      <div className="quantity">
        <button
          className="quantity__adjust"
          disabled={
            quantity === 1 || (product.handle === 'live-lobsters-minimum-2' && quantity === 2)
          }
          onClick={() => {
            setQuantity(q => (q > 1 ? q - 1 : 1));
          }}
        >
          <MinusIcon />
        </button>
        {quantity < 10 ? `0${quantity}` : quantity}
        <button
          className="quantity__adjust"
          disabled={varAvailableQuantity === quantity}
          onClick={() => {
            setQuantity(q => q + 1);
          }}
        >
          <PlusIcon style={{ marginBottom: '1px' }} />
        </button>
      </div>
    </Fragment>
  );
};

const SubscriptionFormControl = ({
  subscribing,
  subscriptionInterval,
  setSubscriptionInterval,
  setSubscribing,
  setSubscriptionModalIsOpen,
}) => {
  return subscribing ? (
    // Clicked State
    <FormControl mb="0" className="subscription-cont subscription-select">
      <div className="label">
        <SubscriptionIcon /> Subscription
      </div>
      <div className="aux-message">
        <InfoIcon className="info" />
        <div className="sub-popover-clicked">
          Your order will ship on the nearest available day based on your subscription interval.
          Prior to your ship date, you’ll be given the option to update your delivery date.
        </div>
      </div>
      <FlexBox>
        <select
          value={subscriptionInterval}
          onChange={e => setSubscriptionInterval(e.target.value)}
          onBlur={e => setSubscriptionInterval(e.target.value)}
        >
          <option value="30">SHIPS EVERY 30 DAYS</option>
          <option value="60">SHIPS EVERY 60 DAYS</option>
          <option value="90">SHIPS EVERY 90 DAYS</option>
        </select>
        <WhiteButton className="close" onClick={() => setSubscribing(false)}>
          <CloseIcon />
        </WhiteButton>
      </FlexBox>
      <ArrowDown className="arrow-down" style={{ pointerEvents: 'none' }} />
    </FormControl>
  ) : (
    // This is the normal state

    <FormControl mb="0" className="subscription-cont">
      <div className="aux-message">
        <div className="sub-popover">
          {/* <BodyText> */}
          Your order will ship on the nearest available day based on your subscription interval.
          Prior to your ship date, you’ll be given the option to update your delivery date.
          {/* </BodyText> */}
        </div>
      </div>
      <WhiteSubscriptionButton
        h="60px"
        w="100%"
        className="subscription-btn"
        onClick={() => setSubscriptionModalIsOpen(true)}
      >
        <span className="sub-cta">
          <SubscriptionIcon /> Subscribe And Save 10%
        </span>
      </WhiteSubscriptionButton>
    </FormControl>
  );
};

const ATCButton = ({
  isAGiftCard,
  handleIsBeforeAddGiftCardToCartModalOpen,
  addToCart,
  availableForSale,
  loadingAvailability,
  subscribing,
}) => {
  return (
    <RedButton
      h="60px"
      w="100%"
      onClick={isAGiftCard ? () => handleIsBeforeAddGiftCardToCartModalOpen(true) : addToCart}
      disabled={!availableForSale || loadingAvailability}
    >
      {availableForSale || loadingAvailability
        ? `Add ${subscribing ? 'subscription ' : ''}to cart`
        : 'Out of stock'}
    </RedButton>
  );
};

export default ProductForm;
